import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, getRefreshToken, renewAccessToken } from "./jwt";
import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import qs from 'qs';
import { Dayjs } from "dayjs";

/**
 * ----------------------------------------------------------------
 * This file contains all the api endpoints to DRF and other places
 * ----------------------------------------------------------------
 */

// ---------------------- PAGE APIS ----------------------
export const LOGOUT_PAGE_API = "/api/frontend/logout/";
export const LOGGED_IN_BASE_PAGE_API = "/api/frontend/logged-in-base/";
export const SIGNUP_PLAN_SELECTION_API = "/api/frontend/signup-plan-selection/";
export const PROFILE_PAGE_API = "/api/frontend/profile/";
export const CONTACT_US_PAGE_API = "/api/frontend/contact-us/";
export const CONNECT_WEBSITE_PAGE_API = "/api/frontend/connect-website/";
export const MAX_WEBSITES_PAGE_API = "/api/frontend/max-websites/";
export const CONTENT_PLAN_PAGE_API = "/api/frontend/content-plan/";
export const ARTICLES_PAGE_API = "/api/frontend/articles/";
export const KEYWORDS_PAGE_API = "/api/frontend/keywords/";
export const ARTICLE_EDIT_PAGE_API = "/api/frontend/articles/edit/";
export const SETTINGS_PAGE_API = "/api/frontend/settings/";
export const PLANS_PAGE_API = "/api/frontend/plans/";
export const WP_SUCCESS_PAGE_API = "/api/frontend/wordpress-integration-success/";
export const WEBFLOW_SUCCESS_PAGE_API = "/api/frontend/webflow-integration-success/";
export const GOOGLE_SUCCESS_PAGE_API = "/api/frontend/google-integrations-success/";
export const INDEXATION_PAGE_API = "/api/frontend/indexation";
export const KEYWORD_RESEARCH_PAGE_API = "/api/frontend/keyword-projects";
export const CONTENT_AUTOMATION_PAGE_API = "/api/frontend/automation-projects";
export const GOOGLE_LOGIN_AND_SIGNUP_PAGE_API = "/api/frontend/google-login-signup/success"
export const KEYWORD_PROJECT_PAGE_API = "/api/frontend/keyword-project";
export const KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API = "/api/frontend/keyword-project/keyword-titles";
export const CREATE_ARTICLE_PAGE_API = "/api/frontend/create-article";
export const PROGRAMMATIC_SEO_API = "/api/frontend/generate-seo-titles/";
export const SEO_TITLES_API = "/api/frontend/get-seo-titles/";
export const INTERNAL_LINKS_API = "/api/frontend/internal-links/";
export const BLOG_PROJECT_URLS_API = "/api/frontend/get-blog-data"
export const GLOSSARY_TOPIC_API = "/api/frontend/get-glossary-topic/"
export const GLOSSARY_KEYWORD_HASH_API = "/api/frontend/get-glossary-by-keyword-hash/"

// ------------------------- API Related Functions -------------------------

export class APIError extends Error {
	statusCode: number
	responseData: { [key: string]: any }

	constructor(statusCode: number, responseData: { [key: string]: any }) {
		super(`API request failed with status ${statusCode}`);
		this.statusCode = statusCode;
		this.responseData = responseData;
		this.name = "APIError";
	}
}

// TODO: Remove this later once all usages have been replaced with Tanstack Query
/**
 * Authenticates and fetches data from provided url path. Auto-renews access token in case it was expired.
 *
 * Only use this to make individual api requests. For page data in react routing, use getLoggedInPageData() and
 * getLoggedOutPageData() functions.
 *
 * @param path - api path.
 * @param requestType - 'get' or 'post' request.
 * @param data - optional data to pass in request.
 */
export async function makeApiRequest(path: string,
	requestType: "get" | "post",
	data?: object): Promise<AxiosResponse> {

	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: requestType,
		url: process.env.REACT_APP_DRF_DOMAIN + path,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	if (data) {
		if (requestType === 'get') {
			axiosConfig.params = data;
		} else {
			axiosConfig.data = data;
		}
	}

	try {
		// success
		return await axios(axiosConfig);
	} catch (err) {
		const axiosError = err as AxiosError;
		// If error is 401, renew the access token using refresh token and then try again.
		// Otherwise log / forward the error.
		if (axiosError.response?.status === 401) {
			let refreshToken: string | null = getRefreshToken();
			if (refreshToken) {
				const renewalSuccessful: boolean = await renewAccessToken(refreshToken);
				if (renewalSuccessful) {
					accessToken = getAccessToken();
					axiosConfig.headers = {
						'Authorization': 'Bearer ' + accessToken
					}
					try {
						// success
						return await axios(axiosConfig);
					} catch (err) {
						// all other status codes
						const axiosError = err as AxiosError
						throw new APIError(axiosError.response!.status, axiosError.response!.data as object);
					}
				} else {
					// TODO: redirect to login page??? Not sure :/
					throw new Error("token renewal failed");
				}
			} else {
				throw new Error("refresh token missing");
			}
		} else {
			// all other status codes
			throw new APIError(axiosError.response!.status, axiosError.response!.data as object);
		}
	}
}

// -------------------------------------------------------------------------------------------------------------------

const axiosInstance = axios.create()

function axiosInterceptorSuccessFn(response: AxiosResponse) {
	return response;
}

async function axiosInterceptorFailFn(error: any) {
	const axiosError = error as AxiosError;
	if (axiosError.response?.status === 401) {
		// Renew access token
		let refreshToken: string | null = getRefreshToken();
		if (refreshToken) {
			// Success
			const renewalSuccessful: boolean = await renewAccessToken(refreshToken);
			if (renewalSuccessful) {
				// return Promise.reject('access_token_renewed');
				return Promise.reject(error);
			} else {
				return Promise.reject("Access token renewal failed. Refresh token is most likely stale.");
			}
			// missing refresh token
		} else {
			return Promise.reject("Refresh token missing");
		}
		// some other error
	} else if (error.response) {
		return Promise.reject(error.response.data);
	} else if (error.request) {
		// The request was made but no response was received
		return Promise.reject({ message: 'No response received from server' });
	} else if (error.message) {
		// Something happened in setting up the request that triggered an Error
		return Promise.reject({ message: error.message });
	} else {
		return Promise.reject(`Request failed with status code ${axiosError.response?.status}`);
	}
}

export function retryFn(failureCount: number, error: any) {
	const axiosError = error as AxiosError;
	return axiosError.response?.status === 401;
}

function makeAuthGetRequest(apiPath: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

function makeGetRequest(apiPath: string, queryData?: object) {
	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		headers: {
			'Content-Type': 'application/json'
		},
		data: qs.stringify(queryData)
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

function makeAuthPostRequest(apiPath: string, postData?: Object) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
		data: postData
	}

	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

function makeDownloadRequest(apiPath: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		responseType: "blob",
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ========================================================================
// ---------------------- ACCOUNT EMAIL VERIFICATION ----------------------
// ========================================================================

export function accountEmailVerificationQuery(token: string | undefined): UseQueryOptions {
	return {
		queryKey: ['accountEmailVerificationAPI'],
		queryFn: () => {
			let axiosConfig: AxiosRequestConfig = {
				method: 'post',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/verify-email/`,
				responseType: 'json',
				data: {
					token: token
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =================================================================
// ---------------------- FORGOT PASSWORD API ----------------------
// =================================================================

export const forgotPasswordMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['forgotPasswordAPI'],
	mutationFn: (email: string) => {
		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/forgot-password/',
			responseType: 'json',
			data: {
				email: email,
			}
		}
		return axiosInstance(axiosConfig);
	},
}


// ================================================================
// ---------------------- RESET PASSWORD API ----------------------
// ================================================================

export const resetPasswordMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	encryptedEmail: string
	resetUID: string
	newPassword: string
}> = {
	mutationKey: ['resetPasswordAPI'],
	mutationFn: (postData: { encryptedEmail: string, resetUID: string, newPassword: string }) => {
		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/reset-password/',
			responseType: 'json',
			data: {
				encrypted_email: postData['encryptedEmail'],
				reset_password_uid: postData['resetUID'],
				new_password: postData['newPassword'],
			}
		}
		return axiosInstance(axiosConfig);
	},
}


// ==============================================================
// ---------------------- DOMAIN CHECK API ----------------------
// ==============================================================

export const domainCheckMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['domainCheckAPI'],
	mutationFn: (domain: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/check-domain/?domain=' + domain,
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===================================================================
// ---------------------- TITLE DESCRIPTION API ----------------------
// ===================================================================

export function titleDescQuery(protocol: string, domain: string, enableAPI: boolean): UseQueryOptions {
	return {
		queryKey: ['titleDescAPI'],
		queryFn: () => titleDescQueryFn(protocol, domain),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: enableAPI,
		cacheTime: 0
	}
}

function titleDescQueryFn(protocol: string, domain: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-title-description/?protocol=${protocol}&domain=${domain}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ---------------------- ICP INDUSTRY API ----------------------
// ==============================================================

export function icpIndustryQuery(domain: string, title: string, description: string, enableAPI: boolean): UseQueryOptions {
	return {
		queryKey: ['icpIndustryAPI'],
		queryFn: () => icpIndustryQueryFn(domain, title, description),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: enableAPI,
		cacheTime: 0
	}
}

function icpIndustryQueryFn(domain: string, title: string, description: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-industry-icp-text/?&domain=${domain}&title=${title}&description=${description}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// =============================================================
// ---------------------- COMPETITORS API ----------------------
// =============================================================

export function competitorsQuery(protocol: string, domain: string): UseQueryOptions {
	return {
		queryKey: ['competitorsAPI'],
		queryFn: () => competitorsQueryFn(protocol, domain),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: false,
		cacheTime: 0,
	}
}

function competitorsQueryFn(protocol: string, domain: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-competitors/?protocol=${protocol}&domain=${domain}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// =================================================================
// ---------------------- CONNECT WEBSITE API ----------------------
// =================================================================

export function connectWebsiteQuery(data: Object): UseQueryOptions {
	return {
		queryKey: ['connectWebsiteAPI'],
		queryFn: () => connectWebsiteQueryFn(data),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: false
	}
}

function connectWebsiteQueryFn(data: Object) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/connect-website/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
		data: data
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// =====================================================================
// ---------------------- LOAD ARTICLE TITLES API ----------------------
// =====================================================================

export function loadArticleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadArticleTitlesAPI'],
		queryFn: () => loadArticleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadArticleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-article-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// =====================================================================
// ------------------ LOAD ARCHIVE ARTICLE TITLES API ------------------
// =====================================================================

export function loadArchiveArticleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadArchiveArticleTitlesAPI'],
		queryFn: () => loadArchiveArticleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadArchiveArticleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-archive-article-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ==============================================================
// ---------------------- POST ARTICLE API ----------------------
// ==============================================================

export const postArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articleUID: string,
	selectedIntegration: string,
	selectedIntegrationUniqueID: string,
	postStatus?: string,
	selectedCategories: number,
	updatePublishedArticle?: boolean
}> = {
	mutationKey: ['postArticleAPI'],
	mutationFn: (data: {
		articleUID: string,
		selectedIntegration: string,
		selectedIntegrationUniqueID: string,
		postStatus?: string,
		selectedCategories: number,
		updatePublishedArticle?: boolean,
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/post-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: data.articleUID,
				selected_integration: data.selectedIntegration,
				selection_integration_id: data.selectedIntegrationUniqueID,
				post_status: data.postStatus,
				selected_categories: data.selectedCategories,
				update_published_article: data.updatePublishedArticle,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ------------------- BULK POST ARTICLE API --------------------
// ==============================================================

export const postBulkArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articlesUID: Array<string>,
	selectedIntegration: string,
	selectedIntegrationUniqueId: string
}> = {
	mutationKey: ['postArticleAPI'],
	mutationFn: (data: {
		articlesUID: Array<string>,
		selectedIntegration: string,
		selectedIntegrationUniqueId: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-post-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: data.articlesUID,
				selected_integration: data.selectedIntegration,
				selected_integration_id: data.selectedIntegrationUniqueId
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ============================================================
// ---------------------- EDIT TITLE API ----------------------
// ============================================================

export const editTitleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articleUID: string,
	title: string
}> = {
	mutationKey: ['editTitleAPI'],
	mutationFn: (postData: { articleUID: string, title: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/edit-article-title/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData.articleUID,
				title: postData.title
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =================================================================
// ------------------ Generate Titles From Keyword API -------------
// =================================================================

export const GenerateTitlesFromKeyword: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword_hash: string,
	location: string,
	count?: number
}> = {
	mutationKey: ['GenerateTitlesFromKeywordAPI'],
	mutationFn: (postData: { keyword_hash: string, location: string, count?: number }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-titles-from-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: postData.keyword_hash,
				location: postData.location,
				count: postData.count ? postData.count : 10
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =================================================================
// -------------------- Generate Titles From GSC API ---------------
// =================================================================

export const GenerateTitlesFromGSCKeyword: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword: string,
	location: string,
	count?: number
}> = {
	mutationKey: ['GenerateTitlesFromKeywordAPI'],
	mutationFn: (postData: { keyword: string, location: string, count?: number }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-titles-from-gsc-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword: postData.keyword,
				location: postData.location,
				count: postData.count ? postData.count : 10
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ---------------------- GENERATE V2 ARTICLE -------------------
// ==============================================================

export const generateV2ArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, { articleUID: string; context?: string }> = {
	mutationKey: ['v2ArticleGenerationAPI'],
	mutationFn: ({ articleUID, context }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-v2-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: articleUID,
				context: context,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// -------------------- BULK GENERATE V2 ARTICLE -------------------
// ==============================================================

export const generateBulkV2ArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['bulkV2ArticleGenerationAPI'],
	mutationFn: (articlesUID: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-generate-v2-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: articlesUID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ---------------- BULK ARCHIVE/UNARCHIVE ARTICLE --------------
// ==============================================================

export const archiveBulkArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articlesUID: Array<string>,
	archiveType: "unarchive" | "archive"
}> = {
	mutationKey: ['bulkArticleGenerationAPI'],
	mutationFn: (postData: { articlesUID: Array<string>, archiveType: "unarchive" | "archive" }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-archive-unarchive-articles/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: postData.articlesUID,
				archive_type: postData.archiveType,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ======================================================================
// ---------------------- LOAD ARTICLE CONTENT API ----------------------
// ======================================================================

export function loadArticleContentQuery(articleUID: string): UseQueryOptions {
	return {
		queryKey: ['loadArticleContentAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-article-content/?article_uid=${articleUID}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: false,
		cacheTime: 0,
	}
}


// ===============================================================
// ---------------------- ARTICLE GENERATED ----------------------
// ===============================================================

export function articleIsGeneratedQuery(articleUID: string): UseQueryOptions {
	return {
		queryKey: ['articleIsGeneratedAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/article-is-generated/?article_uid=${articleUID}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		refetchInterval: (data: any) => {
			if (data && data['data']['generated']) {
				return false
			} else {
				return 10000
			}
		},
		refetchIntervalInBackground: true,
		cacheTime: 0,
	}
}

// ==================================================================
// ---------------------- SAVE ARTICLE CONTENT ----------------------
// ==================================================================

export const saveArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	article_uid: string,
	article_content: string,
	article_description: string,
	article_feedback?: string
}> = {
	mutationKey: ['saveArticleAPI'],
	mutationFn: (postData: { article_uid: string, article_content: string, article_description: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData['article_uid'],
				article_content: postData['article_content'],
				article_description: postData['article_description'],
				article_feedback: postData['article_feedback']
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ---------------------- REMOVE INTEGRATION ----------------------
// ================================================================

export const removeIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	integrationType: string,
	integrationUniqueID?: string
}> = {
	mutationKey: ['removeIntegrationAPI'],
	mutationFn: (data: {
		integrationType: string,
		integrationUniqueID?: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/remove-all-integrations/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				integration_type: data.integrationType,
				integration_unique_id: data.integrationUniqueID ? data.integrationUniqueID : null
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===========================================================
// ---------------------- SAVE SETTINGS ----------------------
// ===========================================================

export const saveSettingsMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	settingsToSave: Array<{ settingName: string, settingValue: any }>
}> = {
	mutationKey: ['saveSettingsAPI'],
	mutationFn: (postData: { settingsToSave: Array<{ settingName: string, settingValue: any }> }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-website-settings/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				settings_to_save: postData['settingsToSave'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===========================================================
// ------------------ SAVE WEBSITE DETAILS -------------------
// ===========================================================

export const saveWebsiteDetailsMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	title: string,
	desc: string,
	ind: string,
	icp: string
}> = {
	mutationKey: ['saveWebsiteDetailsAPI'],
	mutationFn: (postData: { title: string, desc: string, ind: string, icp: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-website-details/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				title: postData['title'],
				desc: postData['desc'],
				ind: postData['ind'],
				icp: postData['icp'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===========================================================
// -------------------- SAVE USER DETAILS --------------------
// ===========================================================

export const saveUserDetailsMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	username: string,
	tz: string
}> = {
	mutationKey: ['saveUserDetailsAPI'],
	mutationFn: (postData: { username: string, tz: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-user-details/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				username: postData['username'],
				tz: postData['tz'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ========================================================================
// ---------------------- SUBSCRIPTION PLAN PURCHASE ----------------------
// ========================================================================

export const purchasePlanMutation: UseMutationOptions<AxiosResponse<any, any>, any,
	{ priceID: string, successURL: string, cancelURL: string }> = {
	mutationKey: ['purchasePlanAPI'],
	mutationFn: (postData: { priceID: string, successURL: string, cancelURL: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/purchase-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				price_id: postData['priceID'],
				success_url: postData['successURL'],
				cancel_url: postData['cancelURL']
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===============================================================
// ---------------------- FREE PLAN PURCHASE ----------------------
// ===============================================================

export const useFreePlanMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['useFreePlanAPI'],
	mutationFn: (priceID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/use-free-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				price_id: priceID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===========================================================
// ---------------------- PLAN PURCHASE ----------------------
// ===========================================================

export const planChangeMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['planChangeAPI'],
	mutationFn: (priceID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/change-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				price_id: priceID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ---------------------- CHECKOUT SUCCESS ----------------------
// ==============================================================

export function checkoutSuccessQuery(checkoutSessionID: string | null): UseQueryOptions {
	return {
		queryKey: ['checkoutSuccessAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/checkout-success/?checkout_session_id=${checkoutSessionID}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ===============================================================
// ---------------------- GET ALL PLAN DATA ----------------------
// ===============================================================

export function getAllPlanDataQuery(): UseQueryOptions {
	return {
		queryKey: ['getAllPlanDataAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-all-plan-data/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ======================================================================
// ---------------------- GET SUBSCRIPTION HISTORY ----------------------
// ======================================================================

export function getSubscriptionHistoryQuery(): UseQueryOptions {
	return {
		queryKey: ['getSubscriptionHistoryAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-subscription-history/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ====================================================================
// ---------------------- GET STRIPE PORTAL LINK ----------------------
// ====================================================================

export function stripePortalLinkQuery(): UseQueryOptions {
	return {
		queryKey: ['stripePortalLinkAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-stripe-portal-link/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =================================================================
// ---------------------- CARD PAYMENT FAILED ----------------------
// =================================================================

export const cardPaymentFailedMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['cardPaymentFailedAPI'],
	mutationFn: (paymentIntentID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/card-payment-failed/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				payment_intent_id: paymentIntentID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =====================================================================
// ---------------------- SEND CONTACT US MESSAGE ----------------------
// =====================================================================

export const contactUsMutation: UseMutationOptions<AxiosResponse<any, any>, any,
	{ subject: string, messageBody: string, files: null | FileList }> = {
	mutationKey: ['contactUsAPI'],
	mutationFn: (postData: { subject: string, messageBody: string, files: null | FileList }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let formData = new FormData();
		formData.append('subject', postData.subject);
		formData.append('message', postData.messageBody);
		if (postData.files) {
			Array.from(postData.files).forEach(file => {
				formData.append(file.name, file);
			});
		}

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/contact-us/send-message/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken,
				'Content-Type': 'multipart/form-data',
			},
			data: formData
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =======================================================================
// ---------------------- RESEND VERIFICATION EMAIL ----------------------
// =======================================================================

export const resendVerificationEmailMutation: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['resendVerificationEmailAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/resend-verification-email/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ---------------------- EMAIL PREFERENCE ----------------------
// ==============================================================

export const emailPreferenceMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	send_notif_email: boolean
}> = {
	mutationKey: ['emailPreferenceAPI'],
	mutationFn: (emailPreference) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-email-pref/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				send_notif_email: emailPreference.send_notif_email,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ---------------------- INTEGRATION APIS ----------------------
// ==============================================================

export const wordpressIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	wpSiteURL?: string,
	wpUsername?: string,
	wpPassword?: string,
}> = {
	mutationKey: ['wordpressIntegrationAPI'],
	mutationFn: (postData) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/wp-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				wp_site_url: postData.wpSiteURL,
				wp_username: postData.wpUsername,
				wp_password: postData.wpPassword,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ---------------------- GOOGLE INTEGRATION ----------------------
// ================================================================

export const googleIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['googleIntegrationAPI'],
	mutationFn: (integrationType) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-integrations/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				integration_type: integrationType
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ---------------------- WEBFLOW INTEGRATION ---------------------
// ================================================================

export const webflowIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	based_on: "app" | "api",
	api_token?: string,
	command?: string,
	collection_id?: string,
	selected_feilds_mapping?: Array<{ code: string, value: string }>
}> = {
	mutationKey: ['webflowIntegrationAPI'],
	mutationFn: (postData: {
		based_on: "app" | "api",
		api_token?: string,
		command?: string,
		collection_id?: string,
		selected_feilds_mapping?: Array<{ code: string, value: string }>
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/webflow-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				'based_on': postData.based_on,
				'token': postData.api_token,
				'execute': postData.command,
				'collection_id': postData.collection_id,
				'fields_mapping': postData.selected_feilds_mapping,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ------------------------ WIX INTEGRATION -----------------------
// ================================================================

export const wixIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	api_token: string,
	site_id: string,
}> = {
	mutationKey: ['wixIntegrationAPI'],
	mutationFn: (postData: {
		api_token: string,
		site_id: string,
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/wix-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				'token': postData.api_token,
				'site_id': postData.site_id,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ================================================================
// ------------------------ WIX INTEGRATION -----------------------
// ================================================================

export const shopifyIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	access_token: string,
	shop_url: string,
}> = {
	mutationKey: ['wixIntegrationAPI'],
	mutationFn: (postData: {
		access_token: string,
		shop_url: string,
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/shopify-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				'access_token': postData.access_token,
				'shop_url': postData.shop_url,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ================================================================
// ------------------------ GHOST INTEGRATION -----------------------
// ================================================================

export const ghostIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	ghost_admin_api_key: string,
	ghost_api_url: string,
}> = {
	mutationKey: ['ghostIntegrationAPI'],
	mutationFn: (postData: {
		ghost_admin_api_key: string,
		ghost_api_url: string,
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/ghost-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				'ghost_admin_api_key': postData.ghost_admin_api_key,
				'ghost_api_url': postData.ghost_api_url,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

/**
 * Triggers download for article.
 */
export const downloadArticle = (downloadApiPath: string) => {
	return makeDownloadRequest(
		downloadApiPath
	);
}

/**
 * For fetching blacklist data for user's active website.
 */
export const getCompetitorDomains = () => {
	return makeAuthGetRequest("/api/frontend/get-competitor-domains/");
}


export const downloadCsv = (queryData: {
	projectId: string
}) => {
	return makeAuthGetRequest(`/api/frontend/get-blog-data?blogProjectId=${queryData.projectId}`)
}


/**
 * Removes user's website competitors.
 */
export const removeCompetitors = (postData: {
	domains: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/remove-competitors/", postData);
}

/**
 * "Add competitors with given domains to user's website.
 */
export const addCompetitors = (postData: {
	domains: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/add-competitors/", postData);
}

/**
 * Fetches data for competitors research page.
 */
export const getCompResearchData = () => {
	return makeAuthGetRequest("/api/frontend/get-competitors-research-data/");
}

/**
 * Generates/Unlocks keywords for provided competitor domain
 */
export const generateCompetitorKeywords = (postData: {
	domain: string,
	selectedLocation?: Object | null,
}) => {
	return makeAuthPostRequest("/api/frontend/generate-competitor-keywords/", postData);
}

/**
 * Fetches keyword data for competitor research.
 * @param queryData
 */
export const getCompetitorResearchKeywordsData = (queryData: {
	competitor_domain: string
}) => {
	return makeAuthGetRequest(
		`/api/frontend/get-competitor-research-keywords-data?competitor_domain=${queryData.competitor_domain}`
	);
}

/**
 * Adds keywords to website's selected list.
 */
export const markKeywordsAsSelected = (postData: {
	keyword_hash_list: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/add-multiple-selected-keyword/", postData);
}

/**
 * Removes given keyword from user's selected keywords list.
 */
export const removeKeywordsFromSelected = (postData: {
	keyword_hash: string
}) => {
	return makeAuthPostRequest("/api/frontend/remove-selected-keyword/", postData);
}

/**
 * Deletes user's current active website.
 */
export const deleteCurrentWebsite = () => {
	return makeAuthPostRequest("/api/frontend/delete-current-website/");
}


/**
 * Clearbit Domain suggestion API.
 */
export const domainSuggestion = (domainName: string) => {
	return makeAuthGetRequest(`/api/frontend/domain-suggestion/?name=${domainName}`);
}


/**
 * Fetches data for user's active website.
 */
export const getGoogleSuggestions = (keyword: string) => {
	return makeAuthGetRequest("/api/frontend/get-google-suggestions/?keyword=" + keyword);
}

/**
 * Fetches latest context.
 */
export const getLatestContext = () => {
	return makeAuthGetRequest("/api/frontend/get-latest-contexts/");
}
// ==============================================================
// ----------------- Keyword Research Projects ------------------
// ==============================================================
export function getKeywordProjects(page: number = 1, pageSize: number = -1) {
	return makeAuthGetRequest(`/api/frontend/get-keyword-projects?page=${page}&per_page=${pageSize}`);
}


// ==============================================================
// ----------------- Keyword Research Project -------------------
// ==============================================================
export function getKeywordProject(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-keyword-project/?project_id=" + projectId);
}


// =================================================================
// ---------------------- UPLOAD KEYWORDS API ----------------------
// =================================================================

export const uploadKeywordsMutationV2: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywords: Array<string>,
	selectedLocation: Object
	projectName: string,
	keywordsAddedUsing?: "csv" | "input-box"
}> = {
	mutationKey: ['uploadKeywordsAPI'],
	mutationFn: (postData: {
		keywords: Array<string>,
		selectedLocation: Object,
		projectName: string,
		keywordsAddedUsing?: "csv" | "input-box",
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-keywords-v2/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords: postData.keywords,
				selectedLocation: postData.selectedLocation,
				project_name: postData.projectName,
				keywords_added_using: postData.keywordsAddedUsing !== undefined ? postData.keywordsAddedUsing : "input-box"
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}



// ==============================================================
// --------------- Keyword Research Project Data ----------------
// ==============================================================
export function getKeywordProjectData(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-keyword-project-data/?project_id=" + projectId);
}


// =================================================================
// ------------- UPLOAD KEYWORDS API TO GET RELATED ----------------
// =================================================================

export const aiKeywordsResearchMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywords: Array<string>,
	selectedLocation: Object
}> = {
	mutationKey: ['aiKeywordsResearchAPI'],
	mutationFn: (postData: {
		keywords: Array<string>,
		selectedLocation: Object
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/ai-keywords-research-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords: postData.keywords,
				selectedLocation: postData.selectedLocation
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================================
// ----------------- google integration fetch connected domains -----------------
// ==============================================================================

export function gscfetchConnectedDomains(): UseQueryOptions {
	return {
		queryKey: ['googleIntegrationFetchConnectedDomainsAPI'],
		queryFn: async () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");
			let fetchConfig: RequestInit = {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			};
			const response = await fetch(process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-integration-fetch-connected-domains/', fetchConfig);
			if (!response.ok) {
				const error = await response.json();
				throw new Error(JSON.stringify(error));
			}
			return response.json();
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const fetchError = error as Error;
			return fetchError.message === 'Network response was not ok';
		},
		cacheTime: 0
	}
}


export function fetchGSCKeywordsData2(selectedDomain: String = ""): UseQueryOptions {
	return {
		queryKey: ['googleIntegrationFetchDataAPI'],
		queryFn: async () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");
			let fetchConfig: RequestInit = {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			};
			const response = await fetch(process.env.REACT_APP_DRF_DOMAIN + "/api/frontend/google-integration-fetch-data-from-domain/?selected_domain=" + selectedDomain, fetchConfig);
			if (!response.ok) {
				const error = await response.json();
				throw new Error(JSON.stringify(error));
			}
			return response.json();
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const fetchError = error as Error;
			return fetchError.message === 'Network response was not ok';
		},
		cacheTime: 0
	}
}

/**
 * Removes given keyword from Keyword Research Project.
 */
export const removeKeywordProjectKeywords = (postData: {
	keyword_project_id: string
	keywords: Array<string>
}) => {
	return makeAuthPostRequest("/api/frontend/remove-keyword-project-keywords/", postData);
}


/**
 * Removes given keyword from Keyword Research Project.
 */
export const createCustomTitleForKeywordMutation = (postData: {
	keyword: string
	keywordHash: string
	customArticleTitle: string
}) => {
	return makeAuthPostRequest("/api/frontend/create-custom-title-for-keyword/", postData);
}

export const createCustomKeywordMutation = (postData: {
	projectId: string
	customKeyword: string
}) => {
	return makeAuthPostRequest("/api/frontend/create-custom-keyword/", postData);
}

export const removeKeywordMutation = (postData: {
	keyword: string
	keywordHash: string
}) => {
	return makeAuthPostRequest("/api/frontend/remove-keyword/", postData);
}

export const removeKeywordProjectMutation = (postData: {
	projectId: string
}) => {
	return makeAuthPostRequest("/api/frontend/remove-keyword-project/", postData);
}

export const removeWebpageUrlMutation = (postData: {
	url: string
}) => {
	return makeAuthPostRequest("/api/frontend/delete-internal-link/", postData);
}

export const saveSummaryMutation = (postData: {
	summary: string
	url: string
}) => {
	return makeAuthPostRequest("/api/frontend/update-summary-internal-link/", postData);
}

// ==============================================================
// ---------------------- FEATURE REQUEST -----------------------
// ==============================================================

export const featureRequestMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['featureRequestAPI'],
	mutationFn: (message) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/feature-request/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				message: message,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===============================================================
// ----------------- New Automation Projects Page ----------------
// ===============================================================
export function getAutomationProjects() {
	return makeAuthGetRequest("/api/frontend/get-automation-projects/");
}


export const saveAutomationProjectMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	selectedKeywordProjectId: string,
	trafficRange: Array<number>,
	articlesCount: number,
	frequency: string,
	integration: string,
	publishState: string,
	publishDays: Array<string>,
	publishTime: string,
	publishOnlyGeneratedArticles: boolean,
	integrationUniqueID: string
}> = {
	mutationKey: ['saveAutomationProjectAPI'],
	mutationFn: (postData: {
		selectedKeywordProjectId: string,
		trafficRange: Array<number>,
		articlesCount: number,
		frequency: string,
		integration: string,
		publishState: string,
		publishDays: Array<string>,
		publishTime: string,
		publishOnlyGeneratedArticles: boolean,
		integrationUniqueID: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-automation-project/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				selectedKeywordProjectId: postData.selectedKeywordProjectId,
				traffic_range: postData.trafficRange,
				articles_count: postData.articlesCount,
				frequency: postData.frequency,
				integration: postData.integration,
				publish_state: postData.publishState,
				publish_days: postData.publishDays,
				publish_time: postData.publishTime,
				publish_only_generated_articles: postData.publishOnlyGeneratedArticles,
				integration_unique_id: postData.integrationUniqueID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


export function getAutomationProjectData(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-automation-project-data/?project_id=" + projectId);
}

export function deleteAutomationProject(projectId: string) {
	return makeAuthPostRequest("/api/frontend/delete-automation-project/", { project_id: projectId });
}


export const updateAutomationProjectMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	automationProjectId: string,
	trafficRangeMin: number,
	trafficRangeMax: number,
	articlesCount: number,
	frequency: string,
	integration: string,
	publishState: string,
	publishDays: Array<string>,
	publishTime: string,
	publishOnlyGeneratedArticles: boolean,
	integrationUniqueID: string
}> = {
	mutationKey: ['updateAutomationProjectAPI'],
	mutationFn: (postData: {
		automationProjectId: string,
		trafficRangeMin: number,
		trafficRangeMax: number,
		articlesCount: number,
		frequency: string,
		integration: string,
		publishState: string,
		publishDays: Array<string>,
		publishTime: string,
		publishOnlyGeneratedArticles: boolean,
		integrationUniqueID: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/update-automation-project/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				automation_project_id: postData.automationProjectId,
				trafficRangeMin: postData.trafficRangeMin,
				trafficRangeMax: postData.trafficRangeMax,
				articles_count: postData.articlesCount,
				frequency: postData.frequency,
				integration: postData.integration,
				publish_state: postData.publishState,
				publish_days: postData.publishDays,
				publish_time: postData.publishTime,
				publish_only_generated_articles: postData.publishOnlyGeneratedArticles,
				integration_unique_id: postData.integrationUniqueID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ============================================================
// ----------------- Schedule Article Mutation ----------------
// ============================================================

export const scheduleArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, any, {
	articleUID: string,
	articleScheduleDate: Dayjs,
	integrationName: string,
	integrationUniqueID: string,
	postStatus: string
}> = {
	mutationKey: ['scheduleArticleMutationAPI'],
	mutationFn: (postData: {
		articleUID: string,
		articleScheduleDate: Dayjs,
		integrationName: string,
		integrationUniqueID: string,
		postStatus: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/schedule-article-auto-publish/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData.articleUID,
				schedule_date: postData.articleScheduleDate,
				selected_integration: postData.integrationName,
				selected_integration_id: postData.integrationUniqueID,
				post_status: postData.postStatus
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===============================================================
// ----------------- Content Calendar Page ----------------
// ===============================================================
export function getScheduledArticles() {
	return makeAuthGetRequest("/api/frontend/get-scheduled-articles/");
}

// =================================================================================
// ------------ Get All Generated Articles for Automation Project -----------------
// =================================================================================
export function getGeneratedArticlesForAutomationProject(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-generated-articles-for-automation-project/?keyword_project_id=" + projectId);
}

// ===============================================================
// ------------------------ Get BackLinks ------------------------
// ===============================================================
export function getBackLinks() {
	return makeAuthGetRequest("/api/frontend/get-backlink/");
}

// ===============================================================
// -------------Get Guest Post Finder Queries --------------------
// ===============================================================
export function getGuestPostFinderQueries() {
	return makeAuthGetRequest("/api/frontend/get-guest-post-finder-queries/");
}
export function getGuestPostFinderQueriesView(queryID) {
	return makeAuthGetRequest(`/api/frontend/get-detail-guest-post-finder-queries/?id=${queryID}`);
}
export function getRedditPostFinderQueries() {
	return makeAuthGetRequest("/api/frontend/get-reddit-post-finder/");
}
export function getRedditPostFinderQueriesView(queryID) {
	return makeAuthGetRequest(`/api/frontend/get-detail-reddit-post-finder/?id=${queryID}`);
}

// ============================================================
// -------------------------- Google Auth ---------------------
// ============================================================

export const googleLoginAndSignupAuth: UseMutationOptions<AxiosResponse<any, any>, any, {
	state: string | null,
	code: string | null,
	scope: string | null,
	country: string,
	signup: boolean
}> = {
	mutationKey: ['googleLoginAndSignupAuthAPI'],
	mutationFn: (data: {
		state: string | null,
		code: string | null,
		scope: string | null,
		country: string,
		signup: boolean
	}) => {
		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-login-signup/success/',
			responseType: 'json',
			data: {
				state: data.state,
				code: data.code,
				scope: data.scope,
				country: data.country,
				signup: data.signup
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ============================================================
// -------------------------- Post Survey ---------------------
// ============================================================

export const postSurvey: UseMutationOptions<AxiosResponse<any, any>, any, any> = {
	mutationKey: ['postSurveyAPI'],
	mutationFn: (data: any) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/post-survey/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				surveyData: JSON.stringify(data)
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ================================================================
// --------------------- REMOVE FEATURED IMAGE --------------------
// ================================================================

export function removeFeaturedImageMutation(postData: {
	articleUID: string
}) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/remove-featured-image/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
		data: {
			article_uid: postData.articleUID
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ================================================================
// ------------------ GENERATE NEW FEAURED IMAGE ------------------
// ================================================================

export function generateNewFeaturedImageMutation(postData: {
	articleUID: string
}) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-new-featured-image/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
		data: {
			article_uid: postData.articleUID
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ================================================================
// --------------------- UPLOAD FEATURED IMAGE --------------------
// ================================================================

export function uploadFeaturedImageMutation(postData: {
	articleUID: string,
	image: any
}) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-featured-image/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken,
		},
		data: {
			article_uid: postData.articleUID,
			image_file: postData.image
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ================================================================
// --------------------- UPLOAD FEATURED IMAGE --------------------
// ================================================================

export function uploadDomainLogoMutation(postData: {
	image: any
}) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");
	const formData = new FormData();
	formData.append("image_file", postData.image);

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-domain-logo/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken,
		},
		data: formData
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ================================================================
// ------------------------- TASK PROGRESS ------------------------
// ================================================================
export function getTaskProgress(taskId: string) {
	return makeAuthGetRequest("/api/frontend/get-task-progress/?task_id=" + taskId);
}

// ================================================================
// ------------------------- WEBFLOW SITES ------------------------
// ================================================================

export function getWebflowSites() {
	return makeAuthGetRequest("/api/frontend/get-webflow-sites");
}

// ================================================================
// ------------------------ WORDPRESS SITES -----------------------
// ================================================================

export function getWordpressSites() {
	return makeAuthGetRequest("/api/frontend/get-wordpress-sites");
}


// ================================================================
// --------------------------- WIX SITES --------------------------
// ================================================================

export function getWixSites() {
	return makeAuthGetRequest("/api/frontend/get-wix-sites");
}

// ================================================================
// --------------------------- SHOPIFY SHOPS ----------------------
// ================================================================

export function getShopifyShops() {
	return makeAuthGetRequest("/api/frontend/get-shopify-shops");
}

// ================================================================
// --------------------------- GHOST CMS ----------------------
// ================================================================

export function getGhostSites() {
	return makeAuthGetRequest("/api/frontend/get-ghost-sites");
}

// =================================================================
// ---------------- LONGTAIL KEYWORD SUGGESTIONS -------------------
// =================================================================

export function getLongtailKeywordSuggestions(keyword: string) {
	return makeAuthGetRequest(`/api/frontend/get-longtail-keyword-suggestions?keyword=${keyword}`);
}

// =================================================================
// ---------------------- ARTICLE PROGRESS -------------------------
// =================================================================

export function getArticleProgress(articleUID: string) {
	return makeAuthGetRequest("/api/frontend/get-logs/?article_uid=" + articleUID);
}

// =================================================================
// ---------------------- WEBSITE SITEMAPS -------------------------
// =================================================================

export function findWebsiteSitemaps() {
	return makeAuthGetRequest("/api/frontend/find-website-sitemaps/");
}

// ==============================================================
// ---------------------- PROGRAMMATIC SEO ----------------------
// ==============================================================

interface CountryType {
	location_code: number;
	location_name: string;
	country_iso_code: string;
}

export const generateSEOTitlesQueryFn = (n_titles: number, pattern: string, example_pattern: string[], selectedLocation: CountryType) => {
	let accessToken = getAccessToken(); // Get the access token

	if (!accessToken) throw new Error("Access token missing");

	const axiosConfig: AxiosRequestConfig = {
		method: 'POST',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/generate-seo-titles/`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${accessToken}`,
		},
		data: {
			n_titles,
			pattern,
			example_pattern,
			selectedLocation,
		},
	};
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
};


export const getTitleProjectsQuery = async () => {
	let accessToken = getAccessToken();

	if (!accessToken) throw new Error("Access token missing");

	const axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-seo-titles/`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${accessToken}`,
		},
	};

	try {
		// First try the API request with the current access token
		const response = await axios(axiosConfig);
		return response.data;
	} catch (error) {
		const axiosError = error as AxiosError;

		if (axiosError.response?.status === 401) {
			// If we get a 401 Unauthorized error, attempt token renewal
			const refreshToken: string | null = getRefreshToken();
			if (refreshToken) {
				try {
					// Attempt to renew the access token using the refresh token
					const renewalSuccessful: boolean = await renewAccessToken(refreshToken);
					if (renewalSuccessful) {
						// After token is renewed, get the new access token
						accessToken = getAccessToken();

						// Update the request config with the new access token
						axiosConfig.headers = {
							'Authorization': 'Bearer ' + accessToken,
						};

						// Retry the request with the new token
						const response = await axios(axiosConfig);
						return response.data;
					} else {
						// If token renewal fails, throw an error or handle login
						throw new Error("Token renewal failed");
					}
				} catch (err) {
					// If there are errors during the renewal process
					throw new APIError(axiosError.response!.status, axiosError.response!.data as object);
				}
			} else {
				// If no refresh token is available
				throw new Error("Refresh token missing");
			}
		} else {
			// Handle other errors (non-401 status codes)
			throw new APIError(axiosError.response!.status, axiosError.response!.data as object);
		}
	}
};

// ===============================================================
// ---------------------- ADD CATEGORY API  ----------------------
// ===============================================================

export const addCategoryMutation: UseMutationOptions<
	AxiosResponse<any, any>,
	any,
	{ wpSiteUrl: string, name: string, description: string }
> = {
	mutationKey: ['addCategoryAPI'],
	mutationFn: (data: { wpSiteUrl: string, name: string, description: string }) => {
		const token = getAccessToken();
		if (!token) throw new Error('Access token is missing');

		const axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/wp-add-category/',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {
				wp_site_url: data.wpSiteUrl,
				name: data.name,
				description: data.description,
			}
		};

		return axios(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		return error?.response?.status === 401;
	}
};

export const wpPostCategoryCheckedMutation: UseMutationOptions<
	AxiosResponse<any, any>,
	any,
	{ articleTitle: string, categoryId: number }
> = {
	mutationKey: ['wpPostCategoryCheckedAPI'],
	mutationFn: (data: { articleTitle: string, categoryId: number }) => {
		const token = getAccessToken();
		if (!token) throw new Error('Access token is missing');

		const axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/wp-post-category-checked/',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {
				article_title: data.articleTitle,
				category_id: data.categoryId,
			}
		};

		return axios(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		return error?.response?.status === 401;
	}
};

// ================================================================
// ---------------------- RETRY CONTENT PLAN ----------------------
// ================================================================

export const retryContentPlanMutation: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['retryContentPlanAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/retry-content-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =========================================================================
// ---------------------- CONTENT PLAN DONE (polling) ----------------------
// =========================================================================

export function contentPlanDonePollingQuery(): UseQueryOptions {
	return {
		queryKey: ['contentPlanDonePollingAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/content-plan-done/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		refetchInterval: (data: any) => {
			if (data && data['data']['status'] === "done") {
				return false
			} else {
				return 10000
			}
		},
		refetchIntervalInBackground: true
	}
}

// ===================================================================
// ---------------------- GET CONTENT PLAN DATA ----------------------
// ===================================================================

export function getContentPlanDataQuery(): UseQueryOptions {
	return {
		queryKey: ['getContentPlanDataAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-content-plan-data/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		cacheTime: 0
	}
}

// ===================================================================
// --------------------------- WEBSITE ICP ---------------------------
// ===================================================================

export const saveWebsiteIcp = (postData: {
	icp: string
}) => {
	return makeAuthPostRequest("/api/frontend/save-website-icp/", postData);
}

// ===================================================================
// ------------------------- WEBSITE INDUSTRY ------------------------
// ===================================================================

export const saveWebsiteIndustry = (postData: {
	industry: string
}) => {
	return makeAuthPostRequest("/api/frontend/save-website-industry/", postData);
}

// ==============================================================
// ------------- INDEXATION: SEND PAGES FOR INDEXING ------------
// ==============================================================

export const sendPageForIndexingMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['sendPageForIndexingAPI'],
	mutationFn: (pages: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/send-pages-for-indexing/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				all_pages_urls: pages
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ----------------- INDEXATION: ALL PAGES API ------------------
// ==============================================================

export function loadAllPageQuery(): UseQueryOptions {
	return {
		queryKey: ['loadAllPageAPI'],
		queryFn: () => loadAllPageQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadAllPageQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/fetch-all-pages/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ------------- INDEXATION: POSTED ABUN ARTICLES ---------------
// ==============================================================

export function loadPostedAbunArticlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadPostedAbunArticlesAPI'],
		queryFn: () => loadPostedAbunArticlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadPostedAbunArticlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/fetch-all-posted-abun-articles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ----------------- INDEXATION: REPORT STATS -------------------
// ==============================================================

export function loadIndexationReportStatsQuery(): UseQueryOptions {
	return {
		queryKey: ['loadIndexationReportStatsAPI'],
		queryFn: () => loadIndexationReportStatsQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadIndexationReportStatsQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/indexation-report-stats/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// -------- INDEXATION: ALL PAGES SENT FOR INDEXING API ---------
// ==============================================================

export function loadPagesSentForIndexingQuery(): UseQueryOptions {
	return {
		queryKey: ['loadPagesSentForIndexingAPI'],
		queryFn: () => loadPagesSentForIndexingQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadPagesSentForIndexingQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/fetch-pages-sent-for-indexing/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ----------------------- INTERNAL LINKS -----------------------
// ==============================================================

export function getScrapedWebPages(): UseQueryOptions {
	return {
		queryKey: ['getScrapedWebPagesAPI'],
		queryFn: () => getScrapedWebPagesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function getScrapedWebPagesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-scraped-web-pages/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ------------------- WEBSITE ANALYSIS STATS -------------------
// ==============================================================

export function getWebsiteAnalysisStats(): UseQueryOptions {
	return {
		queryKey: ['getWebsiteAnalysisStatsAPI'],
		queryFn: () => getWebsiteAnalysisStatsQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

export function getWebsiteAnalysisStatsQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-website-analysis-stats/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ===================================================================
// ---------------------- SWITCH ACTIVE WEBSITE ----------------------
// ===================================================================

export const switchWebsiteMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['switchWebsiteAPI'],
	mutationFn: (newDomain: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/switch-active-website/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				domain: newDomain,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ===================================================================
// ----------------------- CANCEL SUBSCRIPTION -----------------------
// ===================================================================

export const cancelSubscriptionMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['cancelSubscriptionAPI'],
	mutationFn: (subscriptionID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/cancel-user-subscription/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				sub_id: subscriptionID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ===================================================================
// ----------------------- ADD WEBSITE SITEMAP -----------------------
// ===================================================================

export const addSitemap: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['addSitemapAPI'],
	mutationFn: (sitemapUrl: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/add-sitemap/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				sitemap_url: sitemapUrl,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ===================================================================
// ------------------------ ADD INTERNAL LINK ------------------------
// ===================================================================

export const addInternalLink: UseMutationOptions<AxiosResponse<any, any>, any, {internalLink: string, rescan?: boolean}> = {
	mutationKey: ['addInternalLinkAPI'],
	mutationFn: ({ internalLink, rescan }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/add-or-rescan-internal-link/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				internal_link: internalLink,
				rescan: rescan
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ===================================================================
// --------------------- RE-SCRAPE WEBSITE PAGES ---------------------
// ===================================================================

export const reScrapeWebsitePages: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['reScrapeWebsitePagesAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/rescrape-website-pages/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ===================================================================
// -------------------- SCRAPE MORE WEBSITE PAGES --------------------
// ===================================================================

export const scrapeMoreWebsitePages: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['scrapeMoreWebsitePagesAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/scrape-more-website-pages/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// ===================================================================
// -------------------------- BLOG FINDER ----------------------------
// ===================================================================

export const blogFinderMutation: UseMutationOptions<AxiosResponse<any, any>, any, { formData: FormData; signal: AbortSignal }> = {
	mutationKey: ['blogFinderAPI'],
	mutationFn: ({ formData, signal }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/validate-blog-urls/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: formData,
			signal,
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

export function getBlogFinderQuery(selectedTab: string): UseQueryOptions {
	return {
		queryKey: ['getBlogFinderApi',selectedTab],
		queryFn: () => getBlogFinderApi(selectedTab),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function getBlogFinderApi(selectedTab: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");
	const apiEndpoint = selectedTab === "Input Field" 
		? '/api/frontend/get-blog-data' 
		: '/api/frontend/get-blog-bulk-data';

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + apiEndpoint,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ===================================================================
// ------------------------ GLOSSARY GENERATOR -----------------------
// ===================================================================

export const glossaryMutation: UseMutationOptions<
	AxiosResponse<any, any>,
	any,
	{ word: string}
> = {
	mutationKey: ['glossaryGeneratorAPI'],
	mutationFn: (data: { word: string }) => {
		const token = getAccessToken();
		if (!token) throw new Error('Access token is missing');

		const axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-glossary-topic/',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {
				word: data.word,
			}
		};

		return axios(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		return error?.response?.status === 401;
	}
};


export const updateGlossaryMutation: UseMutationOptions<
	AxiosResponse<any, any>,
	any,
	{ projectId: string; glossaryWords: string[]; word: string }
> = {
	mutationKey: ['updateGlossaryAPI'],
	mutationFn: (data: { projectId: string; glossaryWords: string[]; word: string }) => {
		const token = getAccessToken();
		if (!token) throw new Error('Access token is missing');

		const axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/update-glossary-words/',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {
				project_id: data.projectId,
				glossary_words: data.glossaryWords,
				word: data.word,
			},
		};

		return axios(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Retry only if the error status is 401 (Unauthorized)
		return error?.response?.status === 401;
	},
};

export function getGlossaryProjectsQuery(): UseQueryOptions {
	return {
		queryKey: ['getGlossaryProjectsAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-all-glossary-projects/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false
	}
}

export const postGlossaryContentMutation: UseMutationOptions<
	AxiosResponse<any, any>,
	any,
	{ projectId: string}
> = {
	mutationKey: ['glossaryContentAPI'],
	mutationFn: (data: { projectId: string }) => {
		const token = getAccessToken();
		if (!token) throw new Error('Access token is missing');

		const axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-glossary-content/',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {
				project_id: data.projectId,
			}
		};

		return axios(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		return error?.response?.status === 401;
	}
};

export const resumeGlossaryContentMutation: UseMutationOptions<
	AxiosResponse<any, any>,
	any,
	{ taskId: string}
> = {
	mutationKey: ['glossaryContentAPI'],
	mutationFn: (data: { taskId: string }) => {
		const token = getAccessToken();
		if (!token) throw new Error('Access token is missing');

		const axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/resume-bulk-glossary-content/',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {
				task_id: data.taskId,
			}
		};

		return axios(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		return error?.response?.status === 401;
	}
};

export const getGlossaryContentMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['getglossaryContentAPI'],
	mutationFn: (projectId: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-glossary-terms/?project_id=' + projectId,
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const postGlossaryBlogMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywordHash: string,
	selectedIntegration: string,
	selectedIntegrationUniqueID: string,
	postStatus?: string,
	updatePublishedArticle?: boolean
}> = {
	mutationKey: ['postGlossaryAPI'],
	mutationFn: (data: {
		keywordHash: string,
		selectedIntegration: string,
		selectedIntegrationUniqueID: string,
		postStatus?: string,
		updatePublishedArticle?: boolean,
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/post-glossary-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: data.keywordHash,
				selected_integration: data.selectedIntegration,
				selection_integration_id: data.selectedIntegrationUniqueID,
				post_status: data.postStatus,
				update_published_article: data.updatePublishedArticle,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const saveGlossaryMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword_hash: string,
	content: string,
	feedback?: string
}> = {
	mutationKey: ['saveGlossaryAPI'],
	mutationFn: (postData: { keyword_hash: string, content: string}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-glossary-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: postData['keyword_hash'],
				content: postData['content'],
				feedback: postData['feedback']
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const scheduleGlossaryMutation: UseMutationOptions<AxiosResponse<any, any>, any, any, {
	keywordHash: string,
	articleScheduleDate: Dayjs,
	integrationName: string,
	integrationUniqueID: string,
	postStatus: string
}> = {
	mutationKey: ['scheduleGlossaryMutationAPI'],
	mutationFn: (postData: {
		keywordHash: string,
		articleScheduleDate: Dayjs,
		integrationName: string,
		integrationUniqueID: string,
		postStatus: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/schedule-glossary-auto-publish/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: postData.keywordHash,
				schedule_date: postData.articleScheduleDate,
				selected_integration: postData.integrationName,
				selected_integration_id: postData.integrationUniqueID,
				post_status: postData.postStatus
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const editGlossaryTermMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywordHash: string,
	term: string
}> = {
	mutationKey: ['editTermAPI'],
	mutationFn: (postData: { keywordHash: string, term: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/edit-glossary-term-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: postData.keywordHash,
				term: postData.term
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const postBulkGlossaryMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywordHashes: Array<string>,
	selectedIntegration: string,
	selectedIntegrationUniqueId: string
}> = {
	mutationKey: ['postArticleAPI'],
	mutationFn: (data: {
		keywordHashes: Array<string>,
		selectedIntegration: string,
		selectedIntegrationUniqueId: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-post-glossary-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords_hash: data.keywordHashes,
				selected_integration: data.selectedIntegration,
				selected_integration_id: data.selectedIntegrationUniqueId,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const archiveBulkGlossaryMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywordHashes: Array<string>,
	archiveType: "unarchive" | "archive"
}> = {
	mutationKey: ['bulkArticleGenerationAPI'],
	mutationFn: (postData: { keywordHashes: Array<string>, archiveType: "unarchive" | "archive" }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-archive-unarchive-glossaries/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords_hash: postData.keywordHashes,
				archive_type: postData.archiveType,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

/**
 * GET request to fetch all addChangeLogs.
 */
export const getChangeLogs = () => {
	return makeGetRequest("/api/common/get-changelogs/");
}

export function checkRescanStatus(jobId: string = ""): UseQueryOptions {
    return {
        queryKey: ['checkResanStatusAPI', jobId],
        queryFn: async () => {
            if (!jobId) throw new Error("jobId is required");
            let accessToken = getAccessToken();
            if (!accessToken) throw Error("access token missing");

            let fetchConfig: RequestInit = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            const response = await fetch(
                `${process.env.REACT_APP_DRF_DOMAIN}/api/frontend/check-internal-link-job-status/?job_id=${jobId}`,
                fetchConfig
            );

            if (!response.ok) {
                const error = await response.json();
                throw new Error(JSON.stringify(error));
            }

            return response.json();
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            const fetchError = error as Error;
            return fetchError.message === 'Network response was not ok';
        },
        cacheTime: 0
    };
}

// ==============================================================
// ---------------- INTEGRATION FEATURE REQUEST -----------------
// ==============================================================

export const integrationFeatureRequestMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['integrationFeatureRequestAPI'],
	mutationFn: (message) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/integration-feature-request/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				message: message,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ------------------------ SAVE CONTEXT ------------------------
// ==============================================================

export const saveContextMutation: UseMutationOptions<AxiosResponse<any, any>, any, { context: string; oldContext?: string }> = {
	mutationKey: ['saveContextAPI'],
	mutationFn: ({ context, oldContext }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-context-in-settings/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				context: context,
				...(oldContext ? { old_context: oldContext } : {})
			}			
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// -------------- AI CALCULATOR QUERY & MUTATIONS ---------------
// ==============================================================

export function getAICalculatorsQuery(): UseQueryOptions {
	return {
	  queryKey: ['getAICalculatorsAPI'],
	  queryFn: () => getAICalculatorsQueryFn(),
	  refetchOnWindowFocus: false,
	  retry: (failureCount, error) => {
		  // Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		  // In this case return true so that we can attempt the api call again.
		  // return error === 'access_token_renewed';
		  const axiosError = error as AxiosError;
		  return axiosError.response?.status === 401;
	  },
	  cacheTime: 0
	}
  }
  
function getAICalculatorsQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-ai-calculators/`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

export function getAICalculatorDataQuery(calculatorId: string): UseQueryOptions {
return {
	queryKey: ['getAICalculatorData', calculatorId],
	queryFn: () => getAICalculatorDataQueryFn(calculatorId),
	refetchOnWindowFocus: false,
	retry: (failureCount, error) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	},
	cacheTime: 0
}
}

function getAICalculatorDataQueryFn(calculatorId: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-ai-calculator-data/?calculator_id=${calculatorId}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

export const generateAICalculatorMutation:  UseMutationOptions<AxiosResponse<any, any>, any, {calc_instruction: string}> = {
	mutationKey: ['generateAICalculatorAPI'],
	mutationFn: (postData: {calc_instruction: string}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/mini-tool/ai-html-calculator-generator/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				calc_instruction: postData.calc_instruction,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
}

export const modifyAICalculatorMutation:  UseMutationOptions<AxiosResponse<any, any>, any, {calculator_id: string, modifications: string}> = {
	mutationKey: ['modifyAICalculatorAPI'],
	mutationFn: (postData: {calculator_id: string, modifications: string}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/mini-tool/ai-html-calculator-modifier/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				calculator_id: postData.calculator_id,
				modifications: postData.modifications,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
}

// =====================================================================
// ------------ LOAD CONTENT SCHEDULING CALENDER ARTICLE API -----------
// =====================================================================

export function contentSchedulingCalenderArticles(): UseQueryOptions {
	return {
		queryKey: ['contentSchedulingCalenderArticlesAPI'],
		queryFn: () => contentSchedulingCalenderArticlesQuery(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function contentSchedulingCalenderArticlesQuery() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-scheduled-calendar-articles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

export function deleteScheduledCalendarArticle(articleUID: string) {
	return makeAuthPostRequest("/api/frontend/delete-scheduled-calendar-article/", { article_uid: articleUID });
}