import { useMutation } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import { MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import shopifyIconPrimary from "../../assets/images/Shopify-logo-primary.png";
import shopifyIconSuccess from "../../assets/images/Shopify-logo-success.png";
import articleIntegrationIcon from "../../assets/images/article-integration-logo.png";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import webflowIconSuccess from "../../assets/images/webflow-logo.png";
import wixIconPrimary from "../../assets/images/wix-logo-primary.png";
import wixIconSuccess from "../../assets/images/wix-logo-success.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import wordpressIconSuccess from "../../assets/images/wordpress-logo.png";
import ghostIconSuccess from "../../assets/images/ghost-success-icon.png";
import ghostIconPrimary from "../../assets/images/ghost-primary-icon.png";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import GenericButton from "../../components/GenericButton/GenericButton";
import LinkButton from "../../components/LinkButton/LinkButton";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import Survey from "../../components/Survey/Survey";
import { SurveyContext } from "../../pages/Login/SurveyContext";
import AbunLoader from '../../components/AbunLoader/AbunLoader';
import {
	archiveBulkArticleMutation,
	generateBulkV2ArticleMutation,
	generateV2ArticleMutation,
	getArticleProgress,
	postArticleMutation,
	postBulkArticleMutation,
} from "../../utils/api";

import { Link, useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { BasePageData } from "../Base/Base";
import { pageURL } from "../routes";
import "./Articles.scss";

interface ArticlesPageData {
	user_verified: boolean
	stats_title_count?: number
	stats_keywords_count?: number
	stats_competitors_count?: number
	stats_traffic?: Array<number>
	has_active_website: boolean
	all_integrations: Array<string>
	all_integrations_with_unique_id: Array<AllIntegrations>
	google_search_console_integrated: boolean
	articles_generated: number
}

interface ArticlesDataProps {
	userVerified: boolean
	userHasLtdPlans: boolean
	successAlertRef: MutableRefObject<any>
	failAlertRef: MutableRefObject<any>
	setClosablePopup: (value: boolean) => void
	selectedIntegration: string
	selectedIntegrationUniqueID: string,
	integrationWithUniqueID: Array<AllIntegrations>
}

type ArticleTitle = {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
	createdOn: string
	generatedOn: string
	keywordHash: string
	KeywordProjectId: string
	postedOn: string
	scheduledOn: string
}

export type AllIntegrations = {
	integrationName: string
	integrationUniqueID: string
}

interface InitialSortingState {
	id: string;
	desc: boolean;
}



const integrationLogoPrimary = {
	"wordpress": wordpressIconPrimary,
	"webflow": webflowIconPrimary,
	"wix": wixIconPrimary,
	"shopify": shopifyIconPrimary,
	"ghost": ghostIconPrimary,
}

const integrationLogoSuccess = {
	"wordpress": wordpressIconSuccess,
	"webflow": webflowIconSuccess,
	"wix": wixIconSuccess,
	"shopify": shopifyIconSuccess,
	"ghost": ghostIconSuccess,
}

export default function ShowV2Articles() {
	// -------------------------- SURVEY CONTEXT --------------------------
	const context = useContext(SurveyContext);
	const { showSurvey } = context ? context : { showSurvey: false }

	// -------------------------- STATES --------------------------
	const [closablePopup, setClosablePopup] = useState(false);

	// ----------------------- PAGE DATA -----------------------
	const pageData = useLoaderData() as ArticlesPageData;

	// ----------------------- BASE DATA -----------------------
	const basePageData = useRouteLoaderData("base") as BasePageData;

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	useEffect(() => {
		document.title = "Articles | Abun"
	}, []);

	return (
		<>
			{showSurvey && <Survey />}
			{
				closablePopup &&
				<div className={"tipcard card is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column mb-4"}>
					<img src="https://res.cloudinary.com/diaiivikl/image/upload/v1722685153/handy-ezgif.com-gif-to-webp-converter_fq22f0.webp" alt="printer" />
					<div className={"tipcard-content is-flex is-justify-content-center is-align-items-center is-flex-direction-column"}>
						<h2 className={"is-size-4"}>Creating Articles is Easy Peasy!</h2>
						<h6>
							Find Keywords &gt; Choose Keywords to Write Articles for &gt; Choose Relevant Titles &gt; Generate Articles 🎉
						</h6>
						<div className={"tipcard-btns"}>
							<Link className={"button is-primary mt-2"} type="link" to={pageURL['keywordResearch']}>Do Keyword Research</Link>
							<Link className={"button is-primary mt-2"} type="link" to={pageURL['tutorials']}>Watch Video Tutorial</Link>
						</div>
					</div>
					<div className={"close-popup"} onClick={() => setClosablePopup(false)}>
						x
					</div>
				</div>
			}

			<div className="card mt-4 articles-table-card">
				<div className="card-content">
					<div className="content is-flex is-flex-direction-column">
						<ArticlesData userVerified={pageData.user_verified}
							userHasLtdPlans={basePageData.user_has_ltd_plans}
							successAlertRef={successAlertRef}
							failAlertRef={failAlertRef}
							setClosablePopup={setClosablePopup}
							selectedIntegration={pageData.all_integrations.length === 0 ? "" : pageData.all_integrations[0]}
							selectedIntegrationUniqueID={pageData.all_integrations_with_unique_id.length === 0 ? "" : pageData.all_integrations_with_unique_id[0].integrationUniqueID}
							integrationWithUniqueID={pageData.all_integrations_with_unique_id}

						/>
					</div>
				</div>
			</div>
			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />
		</>
	)
}

function ArticlesData(props: ArticlesDataProps) {
	// ----------------------- REFS -----------------------
	const navigate = useNavigate();
	const tableRef = useRef<{ refetchData: () => Promise<void> }>(null);
	const failAlertRef = useRef<any>(null);
	const taskPollingIntervals = useRef({});  // To track intervals for each task
	const navigatedArticles = useRef(new Set<string>());


	// ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// ----------------------- STATES -----------------------
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [integrationModalActive, setIntegrationModalActive] = useState(false);
	const [modalText, setModalText] = useState("");
	const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
	const [initialSortingState, setInitialSortingState] = useState<InitialSortingState[]>([{
		id: "date", desc: true
	}])
	const [bulkActionsEnabled, setBulkActionsEnabled] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [selectedTab, setSelectedTab] = useState("Generated Articles");
	const allBulkOptions = useMemo(() => [
		{
			text: "Publish",
		},
		{
			text: "Archive",
		},
		{
			text: "Unarchive",
		},
	], []);
	const [availableBulkActions, setAvailableBulkActions] = useState(allBulkOptions);
	const [openDropdown, setOpenDropdown] = useState("");
	const [selectedIntegration, setSelectedIntegration] = useState(props.selectedIntegration);
	const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState(props.selectedIntegrationUniqueID);
	const [ArticleGenProgressMap, setArticleGenProgressMap] = useState({}); // Object to track progress of each task
	const [ArticleGenProgressMessageMap, setArticleGenProgressMessageMap] = useState({}); // Object to track messages of each task


	// ----------------------- EFFECTS -----------------------
	const generateArticle = useMutation(generateV2ArticleMutation);
	const postArticle = useMutation(postArticleMutation);
	const postSelectedArticles = useMutation(postBulkArticleMutation);
	const generateSelectedArticles = useMutation(generateBulkV2ArticleMutation);
	const archiveSelectedArticles = useMutation(archiveBulkArticleMutation);

	useEffect(() => {
		// hide the table column according to the selected tab
		const tab_to_hide = document.querySelector("#article-titles-table .hide-column-child")?.parentElement || document.querySelector("#article-titles-table .hide-column");

		// filter the data according to the selected tab
		if (selectedTab === "Generated Articles") {
			const removeOptions = ["Unarchive"];
			const newBulkOptions = allBulkOptions.filter(option => !removeOptions.includes(option.text));
			setAvailableBulkActions(newBulkOptions);
			setBulkActionsEnabled(true);

			while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
				tab_to_hide.classList.remove("hide-column");
			}

		} else if (selectedTab === "Published Articles") {
			const removeOptions = ["Unarchive", "Publish"];
			const newBulkOptions = allBulkOptions.filter(option => !removeOptions.includes(option.text));
			setAvailableBulkActions(newBulkOptions);
			setBulkActionsEnabled(true);

			while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
				tab_to_hide.classList.remove("hide-column");
			}

		} else if (selectedTab === "Archived Articles") {
			setAvailableBulkActions([]);
			setBulkActionsEnabled(false);

			while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
				tab_to_hide.classList.remove("hide-column");
			}

		} else if (selectedTab === "Scheduled Articles") {
			setAvailableBulkActions([]);
			setBulkActionsEnabled(false);

			while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
				tab_to_hide.classList.remove("hide-column");
			}

		}
	}, [allBulkOptions, selectedTab]);


	useEffect(() => {
		if (window.location.search) {
			const searchParams = new URLSearchParams(window.location.search);
			const title = searchParams.get("title");
			setSearchText(title?.toLowerCase() || "");
		}
	}, []);

	// =========================================================
	// ----------------------- MAIN CODE -----------------------
	// =========================================================
	function generateArticleHandler(articleUID: string, articleContext?: string) {
		// Step 1: Start the article generation process
		generateArticle.mutate({ articleUID, context: articleContext ?? "" }, {
			onSuccess: (data) => {
				const responseData = data?.data;

				if (responseData?.status === "sent_for_processing") {
					// Store articleUID in localStorage for tracking
					localStorage.setItem(`articleGen-${articleUID}`, articleUID);

					// Start polling for progress using articleUID
					pollArticleProgress(articleUID);
				} else if (responseData?.status === "rejected") {
					// Handle rejection cases
					const reason = responseData.reason;
					if (reason === "max_limit_reached") {
						failAlertRef.current?.show(
							"Article generation request failed. " +
							"You have reached your max article generation limit for the month."
						);
					} else {
						failAlertRef.current?.show(`Article generation request failed. Error ID: ${reason}`);
					}
				} else {
					failAlertRef.current?.show(
						`Article generation request returned unknown status ${responseData?.status}. Please contact us if there's any issue.`
					);
				}
				setInitialSortingState(initialSortingState);
			},
			onError: (error: Error) => {
				console.error("Article generation request error:", error);
				failAlertRef.current?.show("Article generation request failed. Please try again later");
				setTimeout(() => {
					failAlertRef.current?.close();
				}, 5000);
			},
		});
	}

	// Step 2: Poll for article generation progress using articleUID
	const pollArticleProgress = (articleUID: string) => {
		if (taskPollingIntervals.current[articleUID]) return; // Avoid duplicate polling
		// Poll every 2 seconds
		taskPollingIntervals.current[articleUID] = setInterval(() => {
			getArticleProgress(articleUID).then((res) => {
				if (res.data.progress) {
					// Update progress and message
					const progressInfo = res.data.progress;
					setArticleGenProgressMap(prev => {
						const updatedMap = { ...prev, [articleUID]: progressInfo.percent };
						localStorage.setItem("articleGenProgressMap", JSON.stringify(updatedMap)); // Persist
						return updatedMap;
					});
					setArticleGenProgressMessageMap(prev => {
						const updatedMap = { ...prev, [articleUID]: progressInfo.description };
						localStorage.setItem("articleGenProgressMessageMap", JSON.stringify(updatedMap)); // Persist
						return updatedMap;
					});

					if (progressInfo.percent === 100) {
						// Task is complete
						clearInterval(taskPollingIntervals.current[articleUID]);
						setTimeout(() => {
							navigate(`/articles/edit/${articleUID}/`);
							setTimeout(() => cleanupArticleProgress(articleUID), 3000);
						}, 3000);
					}
				}
			}).catch((error) => {
				console.error("Error fetching article progress:", error);
				clearInterval(taskPollingIntervals.current[articleUID]);
				failAlertRef.current?.show("Error tracking article generation progress. Please try again later.");
				cleanupArticleProgress(articleUID);
			});
		}, 2000);
	};

	useEffect(() => {
		const storedProgress = JSON.parse(localStorage.getItem("articleGenProgressMap") || "{}");
		const storedMessages = JSON.parse(localStorage.getItem("articleGenProgressMessageMap") || "{}");

		setArticleGenProgressMap(storedProgress);
		setArticleGenProgressMessageMap(storedMessages);

		// Restart polling or handle navigation
		Object.keys(storedProgress).forEach((articleUID) => {
			const progress = storedProgress[articleUID];
			if (progress < 100) {
				pollArticleProgress(articleUID); // Restart polling
			} else if (progress === 100 && !navigatedArticles.current.has(articleUID)) {
				navigatedArticles.current.add(articleUID);
				navigate(`/articles/edit/${articleUID}/`);
				cleanupArticleProgress(articleUID);
			}
		});
	}, []);

	//Step 3: Cleanup after completion or error
	const cleanupArticleProgress = (articleUID: string) => {
		setArticleGenProgressMap(prev => {
			const updatedMap = { ...prev };
			delete updatedMap[articleUID];
			return updatedMap;
		});

		setArticleGenProgressMessageMap(prev => {
			const updatedMap = { ...prev };
			delete updatedMap[articleUID];
			return updatedMap;
		});


		// Clear the interval
		if (taskPollingIntervals.current[articleUID]) {
			clearInterval(taskPollingIntervals.current[articleUID]);
			delete taskPollingIntervals.current[articleUID];
		}

		// // Remove the articleUID from localStorage
		localStorage.removeItem(`articleGen-${articleUID}`);
		localStorage.removeItem("articleGenProgressMap");
		localStorage.removeItem("articleGenProgressMessageMap");

	};

	function postToBlogHandler(articleUID: string) {
		props.failAlertRef.current?.close();
		props.successAlertRef.current?.close();
		setModalText("Posting to your website blog. Please wait...");
		setRequestModalActive(true);
		postArticle.mutate({
			articleUID: articleUID,
			selectedIntegration: selectedIntegration,
			selectedIntegrationUniqueID: selectedIntegrationUniqueID,
			selectedCategories: 1
		}, {
			onSuccess: () => {
				setRequestModalActive(false);
				tableRef.current?.refetchData().then(() => {
					props.successAlertRef.current?.show(`Article ${articleUID} was posted to your site successfully!`);
				});
			},
			onError: () => {
				setRequestModalActive(false);
				if (selectedIntegration.includes("wordpress")) {
					props.failAlertRef.current?.show(
						"Publishing failed! WordPress rejected the request. Everything is fine on our end. It is most likely a security plugin installed that is causing the issue or if you are using Cloudflare, it blocks the requests and you will have to add our ip 50.116.30.120 and 45.33.6.112 to the whitelist. Please contact customer support via live chat."
					);
				}
				else {
					props.failAlertRef.current?.show(
						"Publishing failed!. Everything is fine on our end. We've logged the error and are looking into it. Check your site settings and try again later."
					);
				}

			}
		})
	}

	function setIntegrationAndHideDropDownContent(integrate: string) {
		setSelectedIntegration(integrate);
		setOpenDropdown("");
	}

	function getIntegrationName(integrate: string | undefined) {
		if (!integrate) {
			return "wordpress";
		} else {
			if (integrate.includes("wordpress")) {
				return "wordpress";
			} else if (integrate.includes("webflow")) {
				return "webflow";
			} else if (integrate.includes("shopify")) {
				return "shopify";
			} else if (integrate.includes("ghost")) {
				return "ghost";
			} else {
				return "wix";
			}
		}
	}

	function truncateSiteDomain(domain: string) {
		if (domain.includes("Webflow")) {
			domain = domain.replace(new RegExp("Webflow - ", 'g'), '');
		} else if (domain.includes("Wix")) {
			domain = domain.replace(new RegExp("Wix - ", 'g'), '');
		} else if (domain.includes("Shopify")) {
			domain = domain.replace(new RegExp("Shopify - ", 'g'), '');
		} else {
			domain = domain.replace(new RegExp("Wordpress - ", 'g'), '');
		}

		if (domain.length <= 20) {
			return domain;
		} else {
			domain = domain.substring(0, 17) + "...";
		}

		return domain;
	}

	const handleBulkAction = (action: string) => {
		setModalText("Processing request. Please wait...");
		setRequestModalActive(true);

		const articleUIDs: Array<string> = selectedRows?.rows.map(row =>
			(row.original as ArticleTitle).articleUID
		) || [];

		const actions = {
			'Generate': () => generateSelectedArticles.mutate(articleUIDs, {
				onSuccess: handleBulkActionSuccess("Articles are being generated. Please wait for a moment."),
				onError: handleBulkActionError("Failed to generate articles. Please try again after some time.")
			}),
			'Publish': () => postSelectedArticles.mutate({
				articlesUID: articleUIDs,
				selectedIntegration: selectedIntegration,
				selectedIntegrationUniqueId: selectedIntegrationUniqueID
			}, {
				onSuccess: handleBulkActionSuccess("Articles was posted to your site successfully!"),
				onError: handleBulkActionError("Failed to post articles. Please try again after some time.")
			}),
			'Archive': () => archiveSelectedArticles.mutate({
				articlesUID: articleUIDs,
				archiveType: "archive"
			}, {
				onSuccess: handleBulkActionSuccess("Articles are being archived. Please wait for a moment."),
				onError: handleBulkActionError("Failed to archive articles. Please try again after some time.")
			}),
			'Unarchive': () => archiveSelectedArticles.mutate({
				articlesUID: articleUIDs,
				archiveType: "unarchive"
			}, {
				onSuccess: handleBulkActionSuccess("Articles are being unarchived. Please wait for a moment."),
				onError: handleBulkActionError("Failed to unarchive articles. Please try again after some time.")
			})
		};

		actions[action]?.();
	};

	const getNoDataText = (tab: string) => {
		if (tab === "Generated Articles") {
			return "Generated articles will appear here.";
		} else if (tab === "Published Articles") {
			return "Published articles will appear here.";
		} else if (tab === "Archived Articles") {
			return "Archived articles will appear here.";
		} else if (tab === "Scheduled Articles") {
			return "Scheduled articles will appear here.";
		}
		return "No articles found.";
	};

	const handleBulkActionSuccess = (message: string) => () => {
		setRequestModalActive(false);
		// Use the table ref to trigger refetch
		tableRef.current?.refetchData();
		props.successAlertRef.current?.show(message);
	};

	const handleBulkActionError = (message: string) => () => {
		setRequestModalActive(false);
		props.failAlertRef.current?.show(message);
	};

	// ================== Generate table data and render AbunTable component ==================
	const columnHelper = createColumnHelper<ArticleTitle>();

	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
			id: 'articleTitle',
			header: "Article Title",
			cell: cellProps => {
				let processing: boolean = cellProps.row.original.isProcessing;
				let generated: boolean = cellProps.row.original.isGenerated;
				let failed: boolean = cellProps.row.original.isFailed;
				if (generated) {
					return (
						<>
							<Link to={`/articles/edit/${cellProps.row.original.articleUID}/`}
								style={{ textDecoration: "none", color: "#000" }}>
								{cellProps.row.original.articleTitle}
							</Link>
						</>
					)
				} else if (processing) {
					return cellProps.row.original.articleTitle
				} else if (failed) {
					return cellProps.row.original.articleTitle
				} else {
					return cellProps.row.original.articleTitle
				}
			},
			enableGlobalFilter: true,
			enableSorting: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.keyword, {
			id: 'keyword',
			header: "Keyword [Traffic]",
			cell: info => (
				<span
					onClick={() => {
						const row = info.row.original;
						navigate(`/keyword-project/${row.KeywordProjectId}/titles/${row.keywordHash}`)
					}}
				>
					{info.getValue()}
					{info.row.original.keywordTraffic !== 0 && ` [${info.row.original.keywordTraffic}]`}
				</span>
			),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.wordCount, {
			id: 'wordCount',
			header: () => {
				return "Words"
			},
			cell: (info) => {
				if (info.getValue() === null || info.getValue() === 0) {
					return "---";
				} else {
					return info.getValue();
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),

		columnHelper.accessor((row: ArticleTitle) => row.createdOn || row.postedTo || row.generatedOn, {
			id: 'date',
			header: () => {
				//Dynamically changing the headers in different tabs
				if (selectedTab === "Generated Articles" || selectedTab === "Archived Articles") return "Created On";
				if (selectedTab === "Published Articles") return "Published On";
				if (selectedTab === "Scheduled Articles") return "Scheduled On";
				return "Date"; // Fallback header
			},
			cell: cellProps => {
				let selectedDate: any = null;

				if (selectedTab == "Published Articles") {
					selectedDate = cellProps.row.original.postedOn;
				} else if (selectedTab == "Scheduled Articles") {
					selectedDate = cellProps.row.original.scheduledOn;
				} else {
					selectedDate = cellProps.row.original.generatedOn;
				}

				if (!selectedDate) {
					return "---";
				}

				// defining date showing context
				const getRelativeTime = (dateString: string) => {
					const createdDateObj = new Date(dateString);
					const now = new Date();
					const timeDiff = now.getTime() - createdDateObj.getTime();

					// Handle future dates
					if (timeDiff < 0) {
						return "just now";
					}

					const seconds = Math.floor(timeDiff / 1000);
					const minutes = Math.floor(seconds / 60);
					const hours = Math.floor(minutes / 60);
					const days = Math.floor(hours / 24);

					// Check conditions in ascending order of time units
					if (seconds < 60) {
						return "just now";
					}

					if (minutes < 60) {
						return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
					}

					if (hours < 24) {
						return hours === 1 ? "an hour ago" : `${hours} hours ago`;
					}

					if (days > 30) {
						const day = createdDateObj.getDate();
						const month = createdDateObj.toLocaleString('default', { month: 'short' });
						const year = createdDateObj.getFullYear().toString().slice(-2);
						return `${day} ${month}, ${year}`;
					}

					return days === 1 ? "a day ago" : `${days} days ago`;
				};

				return getRelativeTime(selectedDate);
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center',
			}
		}),

		columnHelper.display({
			id: 'generate_articles',
			header: () => "Action",
			cell: cellProps => {
				let processing: boolean = cellProps.row.original.isProcessing;
				let generated: boolean = cellProps.row.original.isGenerated;
				let failed: boolean = cellProps.row.original.isFailed;
				if (generated) {
					return <LinkButton linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
						text={"View"}
						type={"success"}
						width={"100px"}
						outlined={true}
						additionalClassList={["is-small", "more-rounded-borders"]} />
				} else if (processing) {
					const progressMessage = ArticleGenProgressMessageMap[cellProps.row.original.articleUID];
					if (!progressMessage) {
						return (
							<LinkButton
								linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
								text={"Generating..."}
								type={"primary"}
								width={"100px"}
								outlined={true}
								additionalClassList={["is-small", "more-rounded-borders"]}
							/>
						);
					}

					return (
						<AbunButton
							className={"is-outlined is-small comp-research-table-button"}
							type={
								ArticleGenProgressMap[cellProps.row.original.articleUID] < 100
									? "primary"
									: "success"
							}
							clickHandler={() => {
								generateArticleHandler(cellProps.row.original.articleUID);
							}}
							disabled={generateArticle.isLoading || (!props.userVerified && !props.userHasLtdPlans)}
							progress={ArticleGenProgressMap[cellProps.row.original.articleUID] || 0} // Using progress from your map
							progressColor="#007BFF" // Define your progress color
							width={"100px"}
						>
							{progressMessage}
						</AbunButton>
					);
				} else if (failed) {
					return <GenericButton text={"Failed. Retry"}
						type={"danger"}
						width={"100px"}
						disable={generateArticle.isLoading}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => {
							generateArticleHandler(cellProps.row.original.articleUID);
						}} />
				} else {
					return <div data-tooltip-id="generate-article" data-tooltip-content="Verify email to create article">
						<AbunButton
							className={"is-outlined is-small comp-research-table-button"}
							type={
								ArticleGenProgressMap[cellProps.row.original.articleUID] < 100
									? "primary"
									: "success"
							}
							clickHandler={() => {
								generateArticleHandler(cellProps.row.original.articleUID);
							}}
							disabled={generateArticle.isLoading || (!props.userVerified && !props.userHasLtdPlans)}
							progress={ArticleGenProgressMap[cellProps.row.original.articleUID] || 0} // Using progress from your map
							progressColor="#007BFF" // Define your progress color
							width={"100px"}
						>
							{ArticleGenProgressMessageMap[cellProps.row.original.articleUID] || "Create Article"}
						</AbunButton>
						{(!props.userVerified && !props.userHasLtdPlans) && <Tooltip id="generate-article" />}
					</div>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center',
			}
		}),
		columnHelper.display({
			header: () => {
				if (selectedTab === "Published Articles") {
					return "View"
				} else {
					return "Publish"
				}
			},
			id: 'post_article',
			cell: cellProps => {
				let posted: boolean = cellProps.row.original.isPosted;
				let generated: boolean = cellProps.row.original.isGenerated;
				let postedTo: string = cellProps.row.original.postedTo;
				let archived: boolean = cellProps.row.original.isArchived;
				let articleUID: string = cellProps.row.original.articleUID;
				if (posted) {
					return <>
						<a className={"view-on-blog-link"} href={cellProps.row.original.postLink} data-tooltip-id="my-tooltip" target="_blank" rel="noreferrer" data-tooltip-content="View on Blog" >
							<img className={"image"} src={integrationLogoSuccess[postedTo]} alt={"integration-icon"} />
						</a>
						<Tooltip id="my-tooltip" />
					</>
				} else if (generated) {
					return <>
						<div className="publish-container is-flex is-align-items-center is-justify-content-center">
							<button className={"publish-to-blog-btn is-flex is-align-items-center is-justify-content-center"} data-tooltip-id="publish-to-blog" data-tooltip-content={props.integrationWithUniqueID.length > 0 ? "Publish to Blog" : "Connect & Publish Now"}
								onClick={() => {
									if (props.integrationWithUniqueID.length > 0) {
										postToBlogHandler(articleUID);
									} else {
										setIntegrationModalActive(true);
									}
								}}>
								<img className={"image"} src={props.integrationWithUniqueID.length === 0 ? articleIntegrationIcon : integrationLogoPrimary[getIntegrationName(selectedIntegration)]} alt={"integration-icon"} />
							</button>
							{props.integrationWithUniqueID.length > 1 &&
								<div className="dropdown">
									<button className={`dropdown-icon ${openDropdown === articleUID ? "rotate" : ""}`} onClick={() => setOpenDropdown(openDropdown === articleUID ? "" : articleUID)}>&#9662;</button>
									<div className={`dropdown-content ${openDropdown === articleUID ? "show" : ""}`}>
										<h6 className={"dropdown-header"}>
											Choose Integration
										</h6>
										{
											props.integrationWithUniqueID.map((integration, index) => (

												<div key={index} className={"dropdown-item"} onClick={() => {
													setIntegrationAndHideDropDownContent(integration.integrationName);
													setSelectedIntegrationUniqueID(integration.integrationUniqueID);
												}}>
													<input type="radio" name="integration" id={integration.integrationName} checked={selectedIntegration === integration.integrationName} onChange={() => { }} />
													<p>{truncateSiteDomain(integration.integrationName.charAt(0).toUpperCase() + integration.integrationName.slice(1))}</p>
												</div>
											))
										}
									</div>
								</div>
							}
						</div>

						<Tooltip id="publish-to-blog" />
					</>
				} else if (archived) {
					return <GenericButton text={"Unarchive"}
						type={"success"}
						disable={archiveSelectedArticles.isLoading}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => {
							archiveSelectedArticles.mutate({ articlesUID: [cellProps.row.original.articleUID], archiveType: "unarchive" }, {
								onSuccess: () => {
									tableRef.current?.refetchData().then(() => {
										props.successAlertRef.current?.show("Article was unarchived successfully!");
									});
								},
								onError: () => {
									props.failAlertRef.current?.show("Failed to unarchive article. Please try again after some time.");
								}
							});
						}} />
				} else {
					return <></>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		})
	]

	if (bulkActionsEnabled) {
		// Add checkbox if bulk actions are enabled
		columnDefs.splice(0, 0, columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
			id: 'checkbox',
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<IndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
					name={"articleSelection"}
					value={row.original.articleUID}
				/>
			),
			enableGlobalFilter: true,
			enableSorting: false,
		}));
	} else {
		// Remove the checkbox if bulk actions are disabled
		columnDefs.splice(0, 0);
	}

	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	return (
		<>
			{/* ------------------------------ INTEGRATION MODAL ------------------------------ */}
			<AbunModal active={integrationModalActive}
				headerText={""}
				closeable={true}
				closeableKey={true}
				hideModal={() => {
					setIntegrationModalActive(false)
				}}>
				<div className={"has-text-centered"}>
					<h1 className={"is-size-3"}>Publish articles to your blog page!</h1>
					<p className={"mt-4"}>
						Start publishing articles to your blog page by setting up an Integration for your website.
						You can find it under <b>Settings</b> {">"} <b>Integration & Scheduling</b>
					</p>
					<LinkButton text={"Go to Settings"}
						linkTo={pageURL['settings'] + "?tab=integration"}
						type={"primary"}
						additionalClassList={["mt-5"]} />
				</div>
			</AbunModal>

			{/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
			<AbunModal active={requestModalActive}
				headerText={""}
				closeable={false}
				hideModal={() => {
					setRequestModalActive(false)
				}}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<AbunLoader show={requestModalActive} height="20vh" />
				</div>
				<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
			</AbunModal>

			{/* ------------------------------ ARTICLE TITLE TABLE ------------------------------ */}
			<AbunTable
				ref={tableRef}
				serverSide={true}
				apiUrl="/api/frontend/get-article-titles/"
				tableContentName={"Article Titles"}
				id="article-titles-table"
				tableName="Articles"
				searchText={searchText}
				columnDefs={columnDefs}
				pageSizes={pageSizes}
				initialPageSize={pageSizes[1]}
				enableSorting={true}
				queryParams={{
					tab: selectedTab
				}}
				transformResponse={(rawData) => ({
					data: rawData.title_data,
					total: rawData.total
				})}
				handleRowClick={(row) => {
					navigate(`/articles/edit/${row.articleUID}/`);
				}}
				noDataText={getNoDataText(selectedTab)}
				searchboxPlaceholderText={"Search for Title & Keywords..."}
				rowCheckbox={true}
				selectedRowsSetter={selectedRowsSetter}
				selectedTab={selectedTab}
				filterTabs={[
					{
						name: "Generated Articles",
						onClick: () => setSelectedTab("Generated Articles")
					},
					{
						name: "Published Articles",
						onClick: () => setSelectedTab("Published Articles")
					},
					{
						name: "Scheduled Articles",
						onClick: () => setSelectedTab("Scheduled Articles")
					},
					{
						name: "Archived Articles",
						onClick: () => setSelectedTab("Archived Articles")
					}
				]}
				bulkActions={!selectedRows?.rows.length ? [] : availableBulkActions}
				bulkActionsEnabled={bulkActionsEnabled}
				applyAction={handleBulkAction}
			/>
		</>
	)
}
