import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import { getBackLinks } from "../../utils/api";
import { pageURL } from "../routes";
import "./BackLinks.scss";

interface BackLink {
    name: string,
    url: string,
    da_score: number,
    follow_unfollow_link: string,
    submissions: string,
    article_link: string,
    show_on_free_plan: boolean

}

// A simple page that shows a list of block website keywords, allows adding new ones one at a time and deleting existing ones in bulk.
export default function BackLinks() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];

    // -------------------------- STATES --------------------------
    const [backlink, setBacklink] = useState<Array<BackLink>>([]);
    const [upgrade, setUpgrade]   = useState(true)

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data
    } = useQuery({
        queryKey: ['getBackLinks'],
        queryFn: getBackLinks,
        refetchOnWindowFocus: false
    });


    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setUpgrade(data['data']['upgrade'])
            let count = parseInt(data['data']['blured_backlinks'])
            const tempBluredBacklinks:BackLink[] = [];
            for (let index = 1; index <= count; index++) {
                tempBluredBacklinks.push({
                        name: `${index}`,
                        url: `${index}`,
                        da_score: index,
                        follow_unfollow_link: `${index}`,
                        submissions: `${index}`,
                        article_link: `${index}`,
                        show_on_free_plan: false
                    })
            }
            let tempBacklinks = data['data']['backlinks'];
            setBacklink([...tempBacklinks, ...tempBluredBacklinks])
        }
    }, [data]);

    useEffect(() => {
		document.title = "Backlinks | Abun"
	}, []);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<BackLink>();
    const columnDefs: ColumnDef<any, any>[] = [
        
        columnHelper.accessor((row: BackLink) => row.name, {
            id: 'name',
            header: "Name",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
            meta: {
				align: 'center'
			}
        }),
        columnHelper.accessor((row: BackLink) => row.url, {
            id: 'url',
            header: "URL",
            cell: props => {
                return <NavLink to={props.row.original.url} target="_blank">{props.row.original.url}</NavLink>
            },
            enableGlobalFilter: true,
            meta: {
				align: 'center'
			}
        }),
        columnHelper.accessor((row: BackLink) => row.da_score, {
            id: 'da_score',
            header: () => (<div style={{ textAlign: "center" }}>Domain Rating (DR)</div>),
            cell:  props => {
				return <span >{props.row.original.da_score}</span>
			},
            enableGlobalFilter: true,
            meta: {
				align: 'center'
			}
        }),
        columnHelper.accessor((row: BackLink) => row.follow_unfollow_link, {
            id: 'follow_unfollow_link',
            header: () => (<div style={{ textAlign: "center" }}>Do Follow/No Follow Links</div>),
            cell: props => {
				return <span >{props.row.original.follow_unfollow_link}</span>
			},
            enableGlobalFilter: true,
            meta: {
				align: 'center'
			}
        }),
        columnHelper.accessor((row: BackLink) => row.submissions, {
            id: 'submissions',
            header: () => (<div style={{ textAlign: "center" }}>Submissions</div>),
            cell: props => {
				return <span >{props.row.original.submissions}</span>
			},
            enableGlobalFilter: true,
            meta: {
				align: 'center'
			}
        }),
        columnHelper.accessor((row: BackLink) => row.article_link, {
            id: 'article_link',
            header: "How to Get Backlink",
            cell: props => {
                return <NavLink to={props.row.original.article_link} target="_blank">{props.row.original.article_link.length>50 ? `${props.row.original.article_link.slice(0, 50)}...` : props.row.original.article_link}</NavLink>
            },
            enableGlobalFilter: true,
            meta: {
				align: 'center'
			}
        }),
    ]

    // function to determine if a row should be blurred
    const shouldBlurRow = (row: BackLink) => {
        return upgrade? !row.show_on_free_plan : false
    };
    
    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Blocked Website Keywords</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <>
                <div className="card">
                    <div className={"card-content"}>
                    <div className={"is-flex is-justify-content-center is-align-items-center is-flex-direction-column"}>
                            <h1 style={{ fontWeight: "800", fontSize: "2rem", fontFamily: "Bricolage Grotesque"}}>Get 132 High Quality Backlinks for Free.</h1>
                            <p className={"is-size-6 mt-2 mb-4"}>Improve your Domain Authority Score for your sites.</p>
                        </div>                                
                        <AbunTable tableContentName={"BackLinks"}
                                tableData={backlink}
                                columnDefs={columnDefs}
                                pageSizes={pageSizes}
                                initialPageSize={pageSizes[0]}
                                noDataText={"No Backlinks data available."}
                                searchboxPlaceholderText={"Search backlinks..."}
                                shouldBlurRow={shouldBlurRow}
                            />
                            
                        {/* Not upgrade show text */}
                        {upgrade ? <div className={"w-100 is-flex is-justify-content-center is-align-items-center p-2 mb-4 upgrade-container"}>
                            <NavLink to={pageURL['manageSubscription']} className={"upgrade"} style={{position:'absolute',top:'0.1rem'}} >
                                <span className="tag is-info is-light is-rounded" style={{fontSize:"1rem"}}>
                                    Upgrade to unlock all rows ⚡
                                </span>
                            </NavLink>
                        </div> : " "
                        }
                    </div>
                </div>
            </>  
        );
    }
}
