import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./pages/routes";
import './index.scss';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";
import SiteMaintenance from './pages/SiteMaintenance';
import { PostHogProvider } from 'posthog-js/react';
import { quantum } from 'ldrs';

// ---------- Quantum Loader ----------------
quantum.register();

// ----------------------------------------------------

// ---------- ReactGA Initialization ----------------
ReactGA.initialize("G-S6CPNCZ81Q"); // GA Measurement ID

// ---------------- HOTJAR INTEGRATION ----------------
if (process.env.REACT_APP_USE_HOTJAR === "1") {
  const siteId = 3746606;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}
// ----------------------------------------------------

let browserRouter: any;

if (process.env.REACT_APP_ISMAINTENANCE) {
  browserRouter = createBrowserRouter([{
    element: <SiteMaintenance />,
    path: "*"
  }])
} else {
  browserRouter = createBrowserRouter(routes);
}

const router = browserRouter;
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


if (process.env.REACT_APP_USE_POSTHOG === "1") {
  const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  }

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient} >
        <TawkMessengerReact
          propertyId="64e32eb694cf5d49dc6b9649"
          widgetId="1h8bmsuu8" />
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <RouterProvider router={router} />
        </PostHogProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );

} else {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient} >
        <TawkMessengerReact
          propertyId="64e32eb694cf5d49dc6b9649"
          widgetId="1h8bmsuu8" />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
