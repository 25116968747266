
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import { useEffect, useState, MutableRefObject } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	fetchGSCKeywordsData2,
	uploadKeywordsMutationV2,
	GenerateTitlesFromGSCKeyword
} from "../../utils/api";
import GenericButton from "../../components/GenericButton/GenericButton";
import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunButton from "../../components/AbunButton/AbunButton";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../../utils/constants/CountriesforSerp";
import AbunLoader from "../../components/AbunLoader/AbunLoader";
import { useNavigate } from "react-router-dom";
import { CustomContextMenu } from "./KeywordResearch";

interface GSCKeyword {
	keyword: string
	clicks: number
	impressions: number
	ctr: number
	position: number
	generated: boolean;
	processing: boolean;
	failed: boolean;
	project_id: string;
	keyword_hash: string;
}

interface GSCKeywordsProps {
	selectedDomain?: string
	updatePageData: () => void
	handleBackBtnClick: () => void
	failAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	countryCode: string
}

interface CountryType {
	location_code: number;
	location_name: string;
	country_iso_code: string;
	suggested?: boolean;
}

export default function GSCKeywords2(props: GSCKeywordsProps) {
	// -------------------------- NON STATE CONSTANTS --------------------------
	const pageSizes = [30, 100, 500];
	const GSCcolumnHelper = createColumnHelper<GSCKeyword>();
	const [
		selectedLocation,
		setSelectedLocation
	] = useState<CountryType>({
		"location_code": 2840,
		"location_name": "United States",
		"country_iso_code": "US"
	});

	const [
		showAddLocationsModal,
		setShowAddLocationsModal
	] = useState<boolean>(false);

	const [
		singleKeywordTitlesGeneration,
		setSingleKeywordTitlesGeneration
	] = useState<boolean>(false);
	const navigate = useNavigate();

	// -------------------------- STATES --------------------------
	const [gscKeywordstableData, setGscKeywordsTableData] = useState<Array<GSCKeyword>>([]);
	const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
	const [modalText, setModalText] = useState(<></>);
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [requestModalCloseable, setRequestModalCloseable] = useState(false);
	const [mainModalActive, setMainModalActive] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);

	// -------------------------- QUERIES --------------------------
	const getGscData = useQuery(fetchGSCKeywordsData2(props.selectedDomain));

	// -------------------------- MUTATIONS --------------------------
	const keywordsMutation = useMutation(uploadKeywordsMutationV2);
	const generateTitlesFromGSCKeywordMutation = useMutation(GenerateTitlesFromGSCKeyword);

	// -------------------------- TABLE SETUP --------------------------
	const gscColumnDefs: ColumnDef<any, any>[] = [
		// checkbox column
		GSCcolumnHelper.accessor((row: any) => row.keyword as string, {
			id: 'checkbox',
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<IndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
					name={"checkbox"}
					value={row.original.keyword}
				/>
			),
			enableGlobalFilter: true,
			enableSorting: false,
		}),
		GSCcolumnHelper.accessor((row: GSCKeyword) => row.keyword, {
			id: 'keyword',
			header: "Keyword",
			cell: info => info.getValue().toString(),
			enableGlobalFilter: true,
		}),
		GSCcolumnHelper.accessor((row: GSCKeyword) => row.impressions, {
			id: 'impressions',
			header: () => (<div style={{ textAlign: "center" }}>Impressions</div>),
			cell: info => info.getValue().toString(),
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		GSCcolumnHelper.accessor((row: GSCKeyword) => row.ctr, {
			id: 'ctr',
			header: () => (<div style={{ textAlign: "center" }}>CTR</div>),
			cell: info => info.getValue().toString(),
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		GSCcolumnHelper.accessor((row: GSCKeyword) => row.position, {
			id: 'position',
			header: () => (<div style={{ textAlign: "center" }}>Position</div>),
			cell: info => info.getValue().toString(),
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		GSCcolumnHelper.accessor((row: GSCKeyword) => row.position, {
			id: 'action',
			header: () => (<div style={{ textAlign: "center" }}>Generate Titles</div>),
			cell: cellProps => {
				let processing: boolean = cellProps.row.original.processing;
				let generated: boolean = cellProps.row.original.generated;
				let failed: boolean = cellProps.row.original.failed;
				let projectId: string = cellProps.row.original.project_id;
				let keywordHash: string = cellProps.row.original.keyword_hash;
				if (generated) {
					return (
						<CustomContextMenu
							url={`/keyword-project/${projectId}/titles/${keywordHash}`}
							CtrlOrMetaClick={() => { }}
							normalClick={() => {
								navigate(`/keyword-project/${projectId}/titles/${keywordHash}`);
							}}>
							<button
								style={{ width: "100px" }}
								className="button is-success is-outlined is-small more-rounded-borders"
							>
								View
							</button>
						</CustomContextMenu>
					)
				} else if (processing) {
					return (
						<AbunButton
							className={"is-outlined is-small comp-research-table-button"}
							type="primary"
							clickHandler={() => navigate(`/keyword-project/${projectId}/titles/${keywordHash}`)}
						>
							Generating Titles
						</AbunButton>
					);
				} else if (failed) {
					return <GenericButton text={"Failed. Retry"}
						type={"danger"}
						width={"100px"}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => { }}
					/>
				}
				else {
					return (
						<AbunButton type={"success"}
							className={"is-outlined is-small comp-research-table-button"}
							disabled={generateTitlesFromGSCKeywordMutation.isLoading}
							clickHandler={() => {
								// deselect all rows
								selectedRows?.rows.forEach(row => {
									row.toggleSelected()
								});
								// select the current row
								cellProps.row.toggleSelected();
								setShowAddLocationsModal(true);
								setSingleKeywordTitlesGeneration(true);
							}}>Generate
						</AbunButton>
					);
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		})
	];

	// ---------------------- FUNCTIONS ----------------------
	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	// -------------------------- EFFECTS --------------------------
	useEffect(() => {
		// Find the country that matches the `country_iso_code` with `pageData.country_code`
		if (props.countryCode !== "ZZ") {
			const matchedCountry = countries.find(
				(country) => country.country_iso_code === props.countryCode.toUpperCase()
			);
			// If a match is found, update the selected location
			if (matchedCountry) {
				setSelectedLocation(matchedCountry);
			}
		}
	}, [props.countryCode]);

	useEffect(() => {
		const processGscData = async () => {
			if (getGscData.data) {
				setIsProcessing(true); // Set processing to true immediately

				const keywordMap: Record<string, GSCKeyword> = {};

				// Perform filtering
				(getGscData.data as { rows: any[] })?.rows?.forEach((row: any) => {
					const keyword = row.keys[0];

					if (!keywordMap[keyword]) {
						keywordMap[keyword] = {
							clicks: row.clicks,
							keyword: keyword,
							impressions: row.impressions,
							ctr: row.ctr.toFixed(2),
							position: row.position.toFixed(2),
							generated: row.generated ?? null,
							processing: row.processing ?? null,
							failed: row.failed ?? null,
							project_id: row.project_id,
							keyword_hash: row.keyword_hash,
						};
					}
				});

				// Convert the object values back into an array
				const gscKeywords = Object.values(keywordMap);

				// Simulate async to ensure state update propagates
				await new Promise((resolve) => setTimeout(resolve, 0));

				setGscKeywordsTableData(gscKeywords as any);
				setIsProcessing(false); // Processing complete
			}
		};

		processGscData();
	}, [getGscData.data]);



	if (getGscData.isLoading || isProcessing) {
		return (
			<AbunModal active={true}
				headerText={"Loading Google Search Console Data"}
				closeable={false}
				hideModal={() => { }}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<AbunLoader show={isProcessing || getGscData.isLoading} height="20vh" />
				</div>
			</AbunModal>
		)
	} else if (getGscData.isError) {
		let error = JSON.parse((getGscData.error as Error).message) || null;
		if (!error) error = { message: "Error fetching data from Google Search Console!" }
		return (
			<AbunModal active={mainModalActive}
				headerText={"Error fetching data"}
				closeable={true}
				hideModal={() => {
					setMainModalActive(false)
					props.handleBackBtnClick();
				}}>
				<div className="error-div has-text-centered">
					<p className="my-2">{error.message}</p>
					<GenericButton text={"Retry"}
						type={"success"}
						clickHandler={() => getGscData.refetch()} />
				</div>
			</AbunModal>
		)
	} else {
		// show modal to select keywords from GSC data
		return (
			<>
				{gscKeywordstableData && (
					<>
						<AbunTable tableContentName={"Keywords"}
							selectedRowsSetter={selectedRowsSetter}
							rowCheckbox={true}
							tableData={gscKeywordstableData}
							columnDefs={gscColumnDefs}
							pageSizes={pageSizes}
							initialPageSize={pageSizes[2]}
							noDataText={"No keywords available."}
							searchboxPlaceholderText={"Search keywords..."}
							bulkActions={[
								{
									text: "Import",
								}
							]}
							applyAction={(action) => {
								if (action === "Import" && selectedRows) {
									setShowAddLocationsModal(true);
								}
							}}
						/>
						<AbunModal active={requestModalActive}
							headerText={""}
							closeable={requestModalCloseable}
							hideModal={() => {
								setRequestModalActive(false)
								setRequestModalCloseable(false)
							}}>
							<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
								<AbunLoader show={requestModalActive} height="20vh" />
							</div >
							<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
						</AbunModal >
						<div className={"blur-background " + (showAddLocationsModal ? "" : "hidden")}>
							<div className={"confirmation-card w-100 mt-4"}>
								<button className={"delete is-pulled-right"}
									onClick={() => {
										setShowAddLocationsModal(false);
										selectedRows?.rows.forEach(row => {
											row.toggleSelected()
										});
									}} />
								<div className={"confirmation-card-content w-100"}>
									<h3 className={"is-size-5 has-text-centered"}>
										Select the location you want to get keywords volume for:
									</h3>
									<div className={"is-flex is-flex-direction-column is-align-items-center mt-5"}>
										<div className={"form-group location-select"}>
											<Autocomplete
												id="country-select-demo"
												sx={{ width: 300 }}
												options={countries}
												value={selectedLocation}
												autoHighlight
												getOptionLabel={(option) => `${option.location_name} (${option.country_iso_code})`}
												isOptionEqualToValue={(option, value) => option.location_code === value.location_code}
												renderOption={(props, option) => (
													<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
														<img
															loading="lazy"
															width="20"
															srcSet={`https://flagcdn.com/w40/${option.country_iso_code.toLowerCase()}.png 2x`}
															src={`https://flagcdn.com/w20/${option.country_iso_code.toLowerCase()}.png`}
															alt=""
														/>
														{option.location_name} ({option.country_iso_code})
													</Box>
												)}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Location"
														inputProps={{
															...params.inputProps,
															// disable autocomplete and autofill and suggestion
															autoComplete: 'off',
														}}
													/>
												)}
												onChange={(event, option) => {
													if (option) {
														setSelectedLocation(option);
													}
												}}
											/>
										</div>
										<AbunButton type={"primary"}
											className={"mt-5 is-block ml-auto mr-auto"}
											clickHandler={() => {
												if (singleKeywordTitlesGeneration && selectedRows && selectedRows?.rows.length === 1) { // single keyword generation
													setModalText(<p>Processing request. Please wait...</p>);
													setRequestModalActive(true);
													setRequestModalCloseable(false);
													generateTitlesFromGSCKeywordMutation.mutate({
														keyword: (selectedRows?.rows[0].original as GSCKeyword).keyword,
														location: selectedLocation.country_iso_code.toLowerCase()
													}, {
														onSuccess: (data) => {
															// deselect all rows
															selectedRows?.rows.forEach(row => {
																row.toggleSelected()
															});
															setRequestModalActive(false);
															let responseData = (data as any)["data"];
															if (responseData["status"] === "rejected") {
																if (responseData["reason"] === "max_limit_reached") {
																	props.failAlertRef.current?.show("Articles generation request failed. " +
																		"You have reached your max Articles generation limit for the month."
																	);
																} else if (responseData["reason"] === "blocked_keyword_used") {
																	props.failAlertRef.current?.show(responseData["message"]);
																} else if (responseData["reason"] === "max_keywords_limit_reached") {
																	props.failAlertRef.current?.show("Articles generation request failed. " +
																		"You have reached your max Keywords generation limit for the month."
																	);
																} else if (responseData["reason"] === "no_keywords_found") {
																	props.failAlertRef.current?.show("No keywords found. Please try with different keywords.");
																} else {
																	props.failAlertRef.current?.show(
																		`Articles generation request failed. Error ID: ${responseData["reason"]}`
																	);
																}
																setTimeout(() => {
																	props.failAlertRef.current?.close();
																}, 5000);
															} else {
																props.successAlertRef.current?.show("Imported & Title Generated Successfully!");
																setTimeout(() => {
																	props.successAlertRef.current?.close();
																}, 5000);
																const projectId = responseData["project_id"];
																const keywordHash = responseData["keyword_hash"];
																navigate(`/keyword-project/${projectId}/titles/${keywordHash}`)
															}
														},
														onError: ((error: Error) => {
															// deselect all rows
															selectedRows?.rows.forEach(row => {
																row.toggleSelected()
															});
															setRequestModalActive(false);
															if (error.message) {
																props.failAlertRef.current?.show(
																	`Failed to generate new titles. ${error.message}`
																);
															} else {
																props.failAlertRef.current?.show(
																	`Failed to generate new titles. Please try again later.`
																);
															}
															setTimeout(() => {
																props.failAlertRef.current?.close();
															}, 5000);
														})
													});
													setShowAddLocationsModal(false);
												} else if (selectedRows) {
													setModalText(<p>Processing request. Please wait...</p>);
													setRequestModalActive(true);
													setRequestModalCloseable(false);
													let keywords: Array<string> = selectedRows?.rows.map(row => {
														return (row.original as GSCKeyword).keyword as string;
													}) || [];
													// parse keywords
													keywords = keywords.map(keyword => keyword.trim());
													// remove special characters
													keywords = keywords.map(keyword => keyword.replace(/[^a-zA-Z0-9\s]/g, ""));
													// remove extra spaces
													keywords = keywords.map(keyword => keyword.replace(/\s+/g, " "));
													keywords = keywords.filter(keyword => keyword.length > 0);
													keywords = keywords.filter(keyword => keyword.length <= 100);
													keywords = [...new Set(keywords)].sort();
													// upload keywords to database
													keywordsMutation.mutate({
														projectName: props.selectedDomain + " - Imported from GSC",
														selectedLocation: selectedLocation,
														keywords: keywords
													}, {
														onSuccess: (data) => {
															setRequestModalActive(false);
															let responseData = (data as any)["data"];
															if (responseData["status"] === "rejected") {
																if (responseData["reason"] === "max_limit_reached") {
																	props.failAlertRef.current?.show("Keywords generation request failed. " +
																		"You have reached your max Keywords generation limit for the month.");
																} else if (responseData["reason"] === "blocked_keyword_used") {
																	props.failAlertRef.current?.show(responseData["message"]);
																} else if (responseData["reason"] === "no_keywords_found") {
																	props.failAlertRef.current?.show("No keywords found. Please try with different keywords.");
																} else {
																	props.failAlertRef.current?.show(
																		`Keywords generation request failed. Error ID: ${responseData["reason"]}`
																	);
																}
																setTimeout(() => {
																	props.failAlertRef.current?.close();
																}, 5000);
															} else {
																props.successAlertRef.current?.show("Keywords added successfully!");
																setTimeout(() => {
																	props.successAlertRef.current?.close();
																}, 5000);
															}
														},
														onError: (error: Error) => {
															setRequestModalActive(false);
															if (error.message) {
																props.failAlertRef.current?.show(`Failed to add new keywords. ${error.message}`);
															} else {
																props.failAlertRef.current?.show(`Failed to add new keywords. Please try again later.`);
															}
															setTimeout(() => {
																props.failAlertRef.current?.close();
															}, 5000);
														}
													});
													setShowAddLocationsModal(false);
												}
											}}>Proceed
										</AbunButton>
									</div>
								</div>
							</div>
						</div>
					</>
				)
				}
			</>
		)
	}
}
