import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Icon as KitIcon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import shopifyLogo from "../../assets/images/Shopify-logo-success.png";
import abunLogo from "../../assets/images/branding/abun_black_text_logo.png";
import ghostLogo from "../../assets/images/ghost-logo-dark.png";
import ghostIcon from "../../assets/images/ghost-logo-orb.png";
import googleSearchConsoleLogo from "../../assets/images/google-search-console.png";
import plusSign from "../../assets/images/plus.png";
import webflowLogo from "../../assets/images/webflow.png";
import wixLogo from "../../assets/images/wix.png";
import wordpressLogo from "../../assets/images/wordpress.png";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunLoader from '../../components/AbunLoader/AbunLoader';
import AbunModal from "../../components/AbunModal/AbunModal";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import GenericButton from "../../components/GenericButton/GenericButton";
import Input from "../../components/Input/Input";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";

import TextArea from "../../components/TextArea/TextArea";
import {
  getGhostSites,
  getShopifyShops, getWebflowSites, getWixSites, getWordpressSites,
  ghostIntegrationMutation,
  googleIntegrationMutation, gscfetchConnectedDomains,
  integrationFeatureRequestMutation,
  removeIntegrationMutation, shopifyIntegrationMutation, webflowIntegrationMutation,
  wixIntegrationMutation, wordpressIntegrationMutation
} from "../../utils/api";
import "./NewIntegration.scss";

interface featureRequestResponse {
  success: boolean,
  message: string
}

interface WordpressSite {
  site_url?: string;
}

interface WebflowSite {
  site_id: string;
  collection_id: string;
  site_url: string;
}
interface WIXSite {
  site_id: string;
  site_url: string;
}
interface ShopifySite {
  shop_url: string;
}
interface GhostSite {
  site_url: string;
}

interface WordpressIntegrationResponse {
  success: boolean
  authorization_endpoint: string
  message?: string
  err_id?: string
}

interface WebflowIntegrationBasedOnApiResponse {
  success: boolean
  message: string
  collections?: Array<WebflowIntgrationCollection>
  fields?: Array<WebflowCollectionField>
  required_feilds?: number
}

type WebflowIntgrationCollection = {
  collection_id: string
  display_name: string
}

type WebflowCollectionField = {
  field_id: string
  display_name: string
  slug: string
  required: boolean
  field_type: string
  validations: Array<string>
}

type WebflowSelectedFields = {
  code: string,
  value: string,
  validations: Array<string>
}

interface wixIntegrationResponse {
  success: boolean
  message: string
}

interface shopifyIntegrationResponse {
  success: boolean
  message: string
}

interface ghostIntegrationResponse {
  success: boolean
  message: string
}

const WebflowFiledsMappingOptions = [
  {
    displayName: "Article Content",
    code: "article_content",
    requiredFieldType: "RichText"
  },
  {
    displayName: "Feature Image",
    code: "featue_image",
    requiredFieldType: "Image"
  },
  {
    displayName: "Article Short Summary",
    code: "article_short_summary",
    requiredFieldType: "PlainText"
  },
  {
    displayName: "Article Posted Date",
    code: "article_posted_date",
    requiredFieldType: "DateTime"
  },
  {
    displayName: "Article Slug",
    code: "article_slug",
    requiredFieldType: "PlainText"
  },
  {
    displayName: "Article Title",
    code: "article_title",
    requiredFieldType: "PlainText"
  },
]

interface GoogleIntegrationResponse {
  success: boolean
  authorization_endpoint: string
}



export type Integrations = 'wordpress' | 'webflow' | 'wix' | 'shopify';

const NewIntegration = () => {
  // ---------------------------- STATES ----------------------------
  const [activeTab, setActiveTab] = useState("Wordpress");
  const [wordpressSites, setWordpressSites] = useState<WordpressSite[]>([]);
  const [webflowSites, setWebflowSites] = useState<WebflowSite[]>([]);
  const [WIXSites, setWIXSites] = useState<WIXSite[]>([]);
  const [ShopifySites, setShopifySites] = useState<ShopifySite[]>([]);
  const [GSCSites, setGSCSites] = useState("");
  const [GhostSites, setGhostSites] = useState<GhostSite[]>([]);
  const [loading, setLoading] = useState(false);
  const [integrationError, setIntegrationError] = useState("");
  const [integrationDeleteModal, setIntegrationDeleteModal] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState({ type: "", uniqueID: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalWebflowOpen, setIsModalWebflowOpen] = useState(false);
  const [isModalWIXOpen, setIsModalWIXOpen] = useState(false);
  const [isModalShopifyOpen, setIsModalShopifyOpen] = useState(false);
  const [GSCIntegrationProcessing, setGSCIntegrationProcessing] = useState(false);
  const [isModalGhostOpen, setIsModalGhostOpen] = useState(false);

  //Integration Form
  const [featureRequestMessage, setFeatureRequestMessage] = useState("");
  const [featureRequestErrorMessage, setFeatureRequestErrorMessage] = useState("");
  const [featureRequestSuccessMessage, setFeatureRequestSuccessMessage] = useState("");
  const [featureRequestModalActive, setFeatureRequestModalActive] = useState(false);
  const featureRequestMut = useMutation(integrationFeatureRequestMutation);


  // For wordpress integration
  const [wpSiteURL, setWpSiteURL] = useState("");
  const [wpUsername, setWpUsername] = useState("");
  const [wpPassword, setWpPassword] = useState("");
  const [showInstructions, setShowInstructions] = useState(false);
  const [connectionMethod, setConnectionMethod] = useState("url");
  const [showPassword, setShowPassword] = useState(false);
  const [failedToFetchWPRestRoutes, setFailedToFetchWPRestRoutes] = useState(false);

  // For webflow integration
  const [wfAPIToken, setWfAPIToken] = useState("");
  const [webflowCollectionsList, setWebflowCollectionsList] = useState<Array<WebflowIntgrationCollection>>([]);
  const [webflowCollectionFields, setWebflowCollectionFields] = useState<Array<WebflowCollectionField>>([]);
  const [selectedWebflowFields, setSelectedWebflowFields] = useState<Array<WebflowSelectedFields>>([]);
  const [webflowAPICommand, setWebflowAPICommand] = useState("authenticate");
  const [webflowAPIAuthenticated, setWebflowAPIAuthenticated] = useState(false);
  const [selectedWebflowCollectionID, setSelectedWebflowCollectionID] = useState("");
  const [totalFeidlsRequired, setTotalFeidlsRequired] = useState(0);
  const [selectedRequiredFields, setSelectedRequiredFields] = useState<Array<string>>([]);

  // For wix integration
  const [wixAPIToken, setWixAPIToken] = useState("");
  const [wixSiteID, setWixSiteID] = useState("");

  // For shopify integration
  const [shopifyAPIToken, setShopifyAPIToken] = useState("");
  const [shopifyShopURL, setShopifyShopURL] = useState("");

  // For gsc integration
  const getDomainListData = useQuery(gscfetchConnectedDomains());

  // For ghost integration
  const [ghostAPIKey, setGhostAPIKey] = useState("");
  const [ghostSiteURL, setGhostSiteURL] = useState("");

  // ---------------------------- MUTATIONS ----------------------------
  const wordpressIntegrationMut = useMutation(wordpressIntegrationMutation);
  const webflowIntegrationMut = useMutation(webflowIntegrationMutation);
  const wixIntegrationMut = useMutation(wixIntegrationMutation);
  const shopifyIntegrationMut = useMutation(shopifyIntegrationMutation);
  const googleIntegrationMut = useMutation(googleIntegrationMutation);
  const ghostIntegrationMut = useMutation(ghostIntegrationMutation);
  const removeIntegrationMut = useMutation(removeIntegrationMutation);

  // ----------------------- REFS -----------------------
  const successAlertRef = useRef<any>(null);
  const errorAlertRef = useRef<any>(null);

  useEffect(() => {
    const fetchSites = async () => {
      setLoading(true);
      if (activeTab === "Wordpress") {
        try {
          const response = await getWordpressSites();
          if (response?.data?.table_data) {
            setWordpressSites(response.data.table_data);
          }
        } catch (error) {
          console.error("Error fetching WordPress sites:", error);
        }
        finally {
          setLoading(false);
        }
      } else if (activeTab === "Webflow") {
        try {
          const response = await getWebflowSites();
          if (response?.data?.table_data) {
            setWebflowSites(response.data.table_data);
          }
        } catch (error) {
          console.error("Error fetching Webflow sites:", error);
        }
        finally {
          setLoading(false);
        }
      } else if (activeTab === "WIX") {
        try {
          const response = await getWixSites();
          if (response?.data?.table_data) {
            setWIXSites(response.data.table_data);
          }
        } catch (error) {
          console.error("Error fetching WIX sites:", error);
        }
        finally {
          setLoading(false);
        }
      } else if (activeTab === "Shopify") {
        try {
          const response = await getShopifyShops();
          if (response?.data?.table_data) {
            setShopifySites(response.data.table_data);
          }
        } catch (error) {
          console.error("Error fetching WIX sites:", error);
        }
        finally {
          setLoading(false);
        }
      } else if (activeTab === "GSC") {
        try {
          if (getDomainListData.isSuccess) {
            setGSCSites("google-search-console");
          } else if (getDomainListData.isError) {
            console.error('Error:', getDomainListData.error);
          }
        } catch (error) {
          console.error("Error fetching GSC sites:", error);
        }
        finally {
          setLoading(false);
        }
      } else if (activeTab === "Ghost") {
        try {
          const response = await getGhostSites();
          if (response?.data?.table_data) {
            setGhostSites(response.data.table_data);
          }
        } catch (error) {
          console.error("Error fetching Ghost sites:", error);
        }
        finally {
          setLoading(false);
        }
      }
    };

    fetchSites();
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // **Map tab names to logos**
  const tabLogos = {
    Wordpress: wordpressLogo,
    Webflow: webflowLogo,
    WIX: wixLogo,
    Shopify: shopifyLogo,
    Ghost: ghostIcon,
    "GSC": googleSearchConsoleLogo,
  };

  const handleInstructionsToggle = () => {
    setShowInstructions(!showInstructions);
  };

  const removeIntegration = (integrationType, integrationUniqueID) => {
    removeIntegrationMut.mutate(
      {
        integrationType,
        integrationUniqueID,
      },
      {
        onSuccess: () => {
          // Update the state based on the integration type
          switch (integrationType) {
            case 'wordpress':
              setWordpressSites((prevSites) =>
                prevSites.filter((site) => site.site_url !== integrationUniqueID)
              );
              break;
            case 'webflow':
              setWebflowSites((prevSites) =>
                prevSites.filter((site) => site.collection_id !== integrationUniqueID)
              );
              break;
            case 'wix':
              setWIXSites((prevSites) =>
                prevSites.filter((site) => site.site_id !== integrationUniqueID)
              );
              break;
            case 'shopify':
              setShopifySites((prevSites) =>
                prevSites.filter((site) => site.shop_url !== integrationUniqueID)
              );
              break;
            case 'google-search-console':
              setGSCSites("");
              break;
            case 'ghost':
              setGhostSites((prevSites) =>
                prevSites.filter((site) => site.site_url !== integrationUniqueID)
              );
              break;
            default:
              console.error(`Invalid integration type '${integrationType}'`);
          }
          successAlertRef.current?.show(`${integrationType} Account Deleted successfully.`);
        },
      }
    );
  };

  const handleDeleteClick = (integrationType, integrationUniqueID) => {
    // Set the current integration and open the modal
    setCurrentIntegration({ type: integrationType, uniqueID: integrationUniqueID });
    setIntegrationDeleteModal(true);
  };


  function wordpressModalContent() {
    return (
      <div>
        <header className="has-text-centered">
          <div className={"integration-modal-logo"}>
            <img src={abunLogo} alt="abun_logo" width={"auto"} height={35} className={"abun-modal-logo"} />
            <img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
            <img src={wordpressLogo} alt="wp_logo" width={"auto"} height={64} />
          </div>
          <p className="wp-subtitle">Connect your WordPress site with Abun</p>
        </header>

        <div className="card-content">

          <div className="wp-alert-box">
            <span>ⓘ</span>
            <div className="alert-msg">
              <b>Important</b>
              <p>Make sure your WordPress site is running on HTTPS and the Application Password feature is enabled.</p>
            </div>
          </div>


          <div >
            <div className="field">
              <label className="label has-text-black">Connection Method</label>

              <div className="wp-connect-meth">
                <label className="radio-connect">
                  <input
                    type="radio"
                    name="connectionMethod"
                    value="url"
                    style={{borderRadius:'10px'}}
                    checked={connectionMethod === "url"}
                    onChange={() => {
                      setConnectionMethod("url");
                      // Reset wp username & password
                      setWpUsername("");
                      setWpPassword("");
                    }}
                  />
                  Connect with WordPress Site URL
                  <span className="uncollapsed-tag tag is-info is-light is-rounded" style={{ margin: ' 1em 0 1em -1em' }}
                  >
                    Easiest
                  </span>
                </label>

                <label className="radio-connect">
                  <input
                    type="radio"
                    name="connectionMethod"
                    value="credentials"
                    style={{borderRadius:'10px'}}
                    checked={connectionMethod === "credentials"}
                    onChange={() => setConnectionMethod("credentials")}
                  />
                  Connect with Username and Application Password
                </label>
              </div>
            </div>

            {connectionMethod === "url" && (
              <div className="field">
                <label className="label">
                  WordPress Site URL
                  <div className="control">
                    <input
                      value={wpSiteURL}
                      className="input"
                      type={"text"}
                      style={{borderRadius:'10px'}}
                      placeholder={"ex. yourdomain.com"}
                      onChange={(event) => {
                        setWpSiteURL(event.target.value);
                      }} />
                  </div>
                </label>
                <p className="help">Enter your WordPress site URL to begin the connection process.</p>
              </div>
            )}

            {connectionMethod === "credentials" && (
              <div>
                <div className="field">
                  <label className="label">WordPress Site URL</label>
                  <div className="control">
                    <input
                      value={wpSiteURL}
                      className="input"
                      type={"text"}
                      style={{borderRadius:'10px'}}
                      placeholder={"ex. yourdomain.com"}
                      onChange={(event) => {
                        setWpSiteURL(event.target.value);
                      }} />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Wordpress Username</label>
                  <div className="control">
                    <input
                      value={wpUsername}
                      className="input"
                      type="text"
                      placeholder="Enter your wordpress username"
                      autoComplete="new-password"
                      style={{borderRadius:'10px'}}
                      onChange={(event) => {
                        setWpUsername(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Application Password</label>
                  <div className="control">
                    <input
                      value={wpPassword}
                      className="input"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your application password"
                      autoComplete="new-password"
                      style={{borderRadius:'10px'}}
                      onChange={(event) => {
                        setWpPassword(event.target.value);
                      }}
                    />
                    <span
                      onClick={() => setShowPassword((prev) => !prev)}
                      className={"eyeShow-password"}>
                      <KitIcon icon={showPassword ? eye : eyeOff} size={20} />
                    </span>
                  </div>
                </div>

                <hr />

                <div className="accordion">
                  <div className="accordion-trigger" onClick={handleInstructionsToggle}>
                    <p className="is-clickable  has-text-weight-bold">How to get your Application Password</p>
                    <svg width="20" height="auto" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={`arrow-icon ${showInstructions ? "rotate" : ""}`}><path d="M1 1L6 6L11 1" stroke="black" stroke-linecap="round"></path></svg>
                  </div>
                  {showInstructions && (
                    <div className="accordion-content">
                      <p className="is-6 mt-5">
                        1. <b>Log in:</b> Go to 'yourdomain.com/wp-admin' and log in with an admin account.
                      </p>
                      <p className="is-6 mt-5">
                        2. <b>Go to Profile:</b> Click Users {'>'} Profile.
                      </p>
                      <p className="is-6 mt-5 ">
                        3. <b>Find Application Password:</b> Scroll down to the Application Passwords section (ensure you're on WordPress 5.6+).
                      </p>
                      <p className="is-6 mt-5">
                        4. <b>Create a Password:</b> Enter a name(e.g, "Abun Auto Blog") and click Add New Application Password.
                      </p>
                      <p className="is-6 mt-5">
                        5. <b>Copy the Password:</b> Save the generated application password securely.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}

            <hr />

            <GenericButton text={wordpressIntegrationMut.isLoading ? "Please Wait..." : "Connect WordPress"}
              type={"success"}
              disable={wordpressIntegrationMut.isLoading}
              additionalClassList={["pl-6", "pr-6"]}
              clickHandler={() => {
                setFailedToFetchWPRestRoutes(false);

                // add https with domain
                let wpSiteHttpsURL: string;
                try {
                  const parser = new URL(wpSiteURL);
                  // remove 'www.'
                  parser.hostname = parser.hostname.replace("www.", "");
                  wpSiteHttpsURL = `https://${parser.hostname}`;
                } catch {
                  wpSiteHttpsURL = `https://${wpSiteURL}`;
                }

                if (wpSiteURL && wpUsername && wpPassword) {
                  wordpressIntegrationMut.mutate({
                    wpSiteURL: wpSiteHttpsURL,
                    wpUsername: wpUsername,
                    wpPassword: wpPassword
                  }, {
                    onSuccess: (data) => {
                      let response: WordpressIntegrationResponse = data['data'];
                      if (response.success) {

                        successAlertRef.current?.show("New Wordpress Account Integrated successfully.");
                        setIsModalOpen(false);

                        // Fetch updated Wordpress sites
                        getWordpressSites()
                          .then((response) => {
                            if (response?.data?.table_data) {
                              setWordpressSites(response.data.table_data);
                            }
                          })
                          .catch((error) => {
                            console.error("Error fetching Wordpress sites:", error);
                          });


                      } else {
                        if (response.err_id === "FAILED_TO_FETCH_REST_ROUTES") {
                          setFailedToFetchWPRestRoutes(true);
                        } else if (response.message) {
                          setIntegrationError(response.message);
                        } else {
                          setIntegrationError(
                            `We were unable to connect with ${wpSiteHttpsURL}. Please make sure you are using https and that Application Password feature is turned ON.`
                          );
                        }
                      }
                    },
                    onError: () => {
                      setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                    }
                  })
                } else if (wpSiteURL && !wpUsername && !wpPassword) {

                  wordpressIntegrationMut.mutate({
                    wpSiteURL: wpSiteHttpsURL
                  }, {
                    onSuccess: (data) => {
                      let response: WordpressIntegrationResponse = data['data'];
                      if (response.success) {
                        // Send users to wordpress app connection authorization page.
                        window.location.href = response.authorization_endpoint;
                        successAlertRef.current?.show("New Wordpress Account Integrated successfully.");

                        // Fetch updated Wordpress sites
                        getWordpressSites()
                          .then((response) => {
                            if (response?.data?.table_data) {
                              setWordpressSites(response.data.table_data);
                            }
                          })
                          .catch((error) => {
                            console.error("Error fetching Wordpress sites:", error);
                          });

                      } else {
                        if (response.err_id === "FAILED_TO_FETCH_REST_ROUTES") {
                          setFailedToFetchWPRestRoutes(true);
                        } else if (response.message) {
                          setIntegrationError(response.message);
                        } else {
                          setIntegrationError(
                            `We were unable to connect with ${wpSiteHttpsURL}. Please make sure you are using https and that Application Password feature is turned ON.`
                          );
                        }
                      }
                    },
                    onError: (response) => {
                      if (response.err_id === "FAILED_TO_FETCH_REST_ROUTES") {
                        setFailedToFetchWPRestRoutes(true);
                      } else if (response.message) {
                        setIntegrationError(response.message)
                      } else {
                        setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                      }
                    }
                  });
                } else {
                  if (!wpSiteURL) {
                    setIntegrationError("Please provide your wordpress site URL");
                  } else if (!wpUsername) {
                    setIntegrationError("Please provide your wordpress username or email");
                  } else if (!wpPassword) {
                    setIntegrationError("Please provide your wordpress application password");
                  }
                }
              }} />

            {failedToFetchWPRestRoutes ? (
              <div className="wp-failed-to-fetch-rest-route-error-box">
                <h4>
                  🚨 WordPress Authentication Route Not Found!
                </h4>
                <p>Make sure your REST API is enabled.</p>

                <h5>🔧 How to Fix it:</h5>
                <ul>
                  <li>1️⃣ Resave Permalinks (Settings → Permalinks → Save)</li>
                  <li>2️⃣ Disable ModSecurity (via cPanel/.htaccess)</li>
                  <li>3️⃣ Check Security Plugins blocking the API</li>
                </ul>

                <p style={{ fontStyle: "italic" }}>
                  Still stuck? Contact support! 🚀
                </p>
              </div>
            ) : (
              <p className={"has-text-danger mt-4"}>{integrationError}</p>
            )}
          </div>
        </div>
      </div>
    )
  }

  function webflowModalContent() {
    return (
      <>
        <div className={"integration-modal-logo"}>
          <img src={abunLogo} alt="abun_logo" width={"auto"} height={35} className={"abun-modal-logo"} />
          <img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
          <img src={webflowLogo} alt="wp_logo" width={"auto"} height={64} />
        </div>
        <div className={"block integration-modal-content"}>
          <h1 className="title is-4 mb-3">Integrating Abun with Your Webflow Site</h1>
          <p>To get started, follow these steps: </p>
          <ol className="ml-5 mt-2 integration-steps">
            <li>Log in to your Webflow dashboard.</li>
            <li>Open the site settings for the site you want to connect with Abun.</li>
            <li>Select "Apps & Integrations" from the left-hand menu.</li>
            <li>In the "API Access" section, click "Generate API Token".</li>
            <li>Set the following permissions:</li>
            <ul className="ml-5 integration-permissions">
              <li>Assets: Read and write</li>
              <li>CMS: Read and write</li>
              <li>Sites: Read and write</li>
              <li>Authorized user: Read-only</li>
            </ul>
            <li>Copy the generated APl token.</li>
          </ol>
        </div>
        <div className={"mt-5"}>
          <label className={"label"}>
            Webflow API token:
            <Input value={wfAPIToken}
              type={"text"}
              placeholder={"ex. 86ac5cefa3***************cd88"}
              className={"integration-input"}
              onChange={(val) => {
                setWfAPIToken(val);
              }} />
          </label>
          <GenericButton text={webflowIntegrationMut.isLoading && webflowAPICommand == "authenticate" ? "Authenticating..." : webflowAPIAuthenticated ? "Authenticated!" : "Authenticate"}
            type={"success"}
            disable={webflowIntegrationMut.isLoading && webflowAPICommand === "authenticate"}
            additionalClassList={["mt-3", "pl-6", "pr-6"]}
            clickHandler={() => {
              setIntegrationError("");
              setWebflowAPICommand("authenticate");
              setWebflowAPIAuthenticated(false);
              setWebflowCollectionsList([]);
              setWebflowCollectionFields([]);

              if (!wfAPIToken) {
                setIntegrationError("Please provide webflow API token")
              } else {
                webflowIntegrationMut.mutate({ based_on: "api", api_token: wfAPIToken, command: "authenticate" }, {
                  onSuccess: (data) => {
                    let response: WebflowIntegrationBasedOnApiResponse = data['data'];
                    if (response.success) {
                      setWebflowAPIAuthenticated(true);
                      setWebflowCollectionsList(response.collections as Array<WebflowIntgrationCollection>);
                    } else {
                      setIntegrationError(response.message);
                    }
                  },
                  onError: () => {
                    setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                  }
                });
              }
            }} />
          <label className={"label mt-6"}>
            Webflow Collection
            <br />
            <div className="select" style={{ width: "100%" }}>
              <select disabled={!webflowCollectionsList} defaultValue={"Please Select a CMS Collection"} style={{ width: "100%", borderRadius:"10px" }}
                onChange={(e) => {
                  let collection_id = e.target.value;

                  setIntegrationError("");
                  setWebflowAPICommand("fields");
                  setWebflowCollectionFields([]);
                  setSelectedWebflowFields([]);
                  setSelectedRequiredFields([]);

                  if (!collection_id) {
                    setWebflowCollectionFields([]);
                  } else {
                    setSelectedWebflowCollectionID(e.target.value);
                    webflowIntegrationMut.mutate({ based_on: "api", api_token: wfAPIToken, command: "fields", collection_id: collection_id }, {
                      onSuccess: (data) => {
                        let response: WebflowIntegrationBasedOnApiResponse = data['data'];
                        if (response.success) {
                          setWebflowCollectionFields(response.fields as Array<WebflowCollectionField>);
                          setTotalFeidlsRequired(!response.required_feilds ? 0 : response.required_feilds);
                          setWebflowAPICommand("connect");
                        } else {
                          setWebflowCollectionFields([]);
                          setIntegrationError(response.message);
                        }
                      },
                      onError: () => {
                        setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                      }
                    });
                  }
                }}>
                <option key={1} value={""}>Please select a CMS Collection</option>
                {webflowCollectionsList?.map(collection => (
                  <option key={collection.collection_id} value={collection.collection_id}>{collection.display_name}</option>
                ))}
              </select>
            </div>
          </label>
          {webflowCollectionFields.length > 0 &&
            webflowCollectionFields.map((field) => (
              <label key={field.field_id} className={"label mt-4"}>
                {field.display_name} {field.required ? <span className="has-text-danger">*</span> : ""}
                <br />
                <div className="select" style={{ width: "100%" }}>
                  <select defaultValue={"Please map an option with this field"} style={{ width: "100%" }}
                    onChange={(e) => {
                      let field_code = e.target.value;
                      if (field_code) {
                        setSelectedWebflowFields([...selectedWebflowFields, { code: field_code, value: field.slug, validations: field.validations }]);
                        if (field.required && !selectedRequiredFields.includes(field.slug)) {
                          setSelectedRequiredFields(prev => [...prev, field.slug]);
                        }
                      } else {
                        setSelectedWebflowFields((prev) => (prev.filter(item => item.value !== field.slug)));
                        setSelectedRequiredFields((prev) => (prev.filter(slug => slug !== field.slug)));
                      }
                    }}>
                    <option key={1} value={""}>Please map an option with this field</option>
                    {WebflowFiledsMappingOptions?.map(option => (
                      (field.field_type === option.requiredFieldType) &&
                      <option key={option.code} value={option.code}>{option.displayName}</option>
                    ))}
                  </select>
                </div>
              </label>
            ))
          }
          <GenericButton text={webflowIntegrationMut.isLoading && webflowAPICommand === "connect" && selectedRequiredFields.length === totalFeidlsRequired ? "Please Wait..." : "Connect"}
            type={"success"}
            disable={webflowIntegrationMut.isLoading || webflowAPICommand !== "connect" || selectedRequiredFields.length !== totalFeidlsRequired}
            additionalClassList={["mt-5", "pl-6", "pr-6"]}
            clickHandler={() => {
              setIntegrationError("");
              webflowIntegrationMut.mutate({
                based_on: "api",
                api_token: wfAPIToken,
                command: "connect",
                collection_id: selectedWebflowCollectionID,
                selected_feilds_mapping: selectedWebflowFields,
              }, {
                onSuccess: (data) => {
                  let response: WebflowIntegrationBasedOnApiResponse = data['data'];
                  if (response.success) {
                    successAlertRef.current?.show(`Your Webflow account connected to abun successfully!`);
                    setIsModalWebflowOpen(false);
                    // Fetch updated Webflow sites
                    getWebflowSites()
                      .then((response) => {
                        if (response?.data?.table_data) {
                          setWebflowSites(response.data.table_data);
                        }
                      })
                      .catch((error) => {
                        console.error("Error fetching Webflow sites:", error);
                      });

                  } else {
                    setIntegrationError(response.message);
                  }
                },
                onError: () => {
                  setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                }
              });
            }} />
        </div>
        <p className={"has-text-danger mt-4"}>{integrationError}</p>
      </>
    )
  }

  function wixModalContent() {
    return (
      <>
        <div className={"integration-modal-logo"}>
          <img src={abunLogo} alt="abun_logo" width={"auto"} height={35} className={"abun-modal-logo"} />
          <img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
          <img src={wixLogo} alt="wp_logo" width={"auto"} height={64} />
        </div>
        <div className={"block integration-modal-content"}>
          <h1 className="title is-4 mb-3">Integrating Abun with Your WIX Site</h1>
          <p>To get started, follow these steps: </p>
          <ol className="ml-5 mt-2 integration-steps">
            <li>Log in to your WIX site dashboard.</li>
            <li>Copy the Site ID from the URL.</li>
            <li>After this go to account settings.</li>
            <li>In the "API Keys" section, click "Generate API Key".</li>
            <li>Check the following permissions:</li>
            <ul className="ml-5 ">
              <li> - WIX Blogs from "All site permissions"</li>
            </ul>
            <li>Copy the generated APl key.</li>
          </ol>
        </div>
        <div className={"mt-5"}>
          <label className={"label"}>
            WIX Site ID:
            <Input value={wixSiteID}
              type={"text"}
              placeholder={"ex. cc745-2f6***************c1ea-7bb2c2e1"}
              className={"integration-input"}
              onChange={(val) => {
                setWixSiteID(val);
              }} />
          </label>
          <label className={"label mt-5"}>
            WIX API key:
            <Input value={wixAPIToken}
              type={"text"}
              placeholder={"ex. IST.eyJraWQiOiJQ***************c3no3w"}
              className={"integration-input"}
              onChange={(val) => {
                setWixAPIToken(val);
              }} />
          </label>
          <GenericButton text={wixIntegrationMut.isLoading ? "Connecting..." : "Connect"}
            type={"success"}
            disable={wixIntegrationMut.isLoading}
            additionalClassList={["mt-6", "mb-2", "pl-6", "pr-6"]}
            clickHandler={() => {
              setIntegrationError("");

              if (!wixAPIToken) {
                setIntegrationError("Please provide WIX API key")
              } else if (!wixSiteID) {
                setIntegrationError("Please provide WIX Site ID")
              } else {
                wixIntegrationMut.mutate({ api_token: wixAPIToken, site_id: wixSiteID }, {
                  onSuccess: (data) => {
                    let response: wixIntegrationResponse = data['data'];
                    if (response.success) {
                      successAlertRef.current?.show("New WIX Account Integrated successfully.");
                      setIsModalWIXOpen(false);

                      // Fetch updated Wix sites
                      getWixSites()
                        .then((response) => {
                          if (response?.data?.table_data) {
                            setWIXSites(response.data.table_data);
                          }
                        })
                        .catch((error) => {
                          console.error("Error fetching Wix sites:", error);
                        });

                    } else {
                      setIntegrationError(response.message);
                    }
                  },
                  onError: () => {
                    setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                  }
                });
              }
            }} />
        </div>
        <p className={"has-text-danger mt-4"}>{integrationError}</p>
      </>
    )
  }

  function shopifyModalContent() {
    return (
      <>
        <div className={"integration-modal-logo"}>
          <img src={abunLogo} alt="abun_logo" width={"auto"} height={35} className={"abun-modal-logo"} />
          <img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
          <img src={shopifyLogo} alt="shopify_logo" width={"auto"} height={64} />
        </div>
        <div className={"block integration-modal-content"}>
          <p>To get started, follow these steps: </p>
          <ol className="ml-5 mt-2 integration-steps">
            <li>Log in to Shopify:</li>
            <ul className="ml-5 ">
              <li> - Open your Shopify admin and copy your Shop URL</li>
            </ul>
            <li>Enable App Development:</li>
            <ul className="ml-5 ">
              <li> - Go to <b>Settings {">"} Apps and Sales Channels</b></li>
              <li> - Click <b>Develop Apps</b> and enable custom app development</li>
            </ul>
            <li>Create an App:</li>
            <ul className="ml-5">
              <li> - Click <b>Create App</b> and give it a name <code>Abun-Integration</code></li>
            </ul>
            <li>Set API Permissions:</li>
            <ul className="ml-5">
              <li> - In <b>API Credentials,</b> click <b>Configure Admin API Scopes</b></li>
              <li> - Search blog and enable <code>write_content & read_content</code></li>
              <li> - Search file and enable <code>write_files & read_files</code></li>
              <li> - Save your changes</li>
            </ul>
            <li>Install the App:</li>
            <ul className="ml-5">
              <li> - Go back to <b>API Credentials</b> and install the app</li>
              <li> - Copy the API token for use with Abun</li>
            </ul>
          </ol>
        </div>
        <div className={"mt-5"}>
          <label className={"label"}>
            SHOPIFY shop URL:
            <Input value={shopifyShopURL}
              type={"text"}
              placeholder={"ex. example.myshopify.com"}
              className={"integration-input"}
              onChange={(val) => {
                setShopifyShopURL(val);
              }} />
          </label>
          <label className={"label mt-5"}>
            SHOPIFY API token:
            <Input value={shopifyAPIToken}
              type={"text"}
              placeholder={"ex. shpat_eyJraWQiOiJ***************c3no3w"}
              className={"integration-input"}
              onChange={(val) => {
                setShopifyAPIToken(val);
              }} />
          </label>
          <GenericButton text={shopifyIntegrationMut.isLoading ? "Connecting..." : "Connect"}
            type={"success"}
            disable={shopifyIntegrationMut.isLoading}
            additionalClassList={["mt-6", "mb-2", "pl-6", "pr-6"]}
            clickHandler={() => {
              setIntegrationError("");

              if (!shopifyAPIToken) {
                setIntegrationError("Please provide SHOPIFY API key")
              } else if (!shopifyShopURL) {
                setIntegrationError("Please provide SHOPIFY Shop Name")
              } else {
                shopifyIntegrationMut.mutate({ access_token: shopifyAPIToken, shop_url: shopifyShopURL }, {
                  onSuccess: (data) => {
                    let response: shopifyIntegrationResponse = data['data'];
                    if (response.success) {
                      successAlertRef.current?.show("New Shopify Account Integrated successfully.");
                      setIsModalShopifyOpen(false);
                      // Fetch updated Shopify sites
                      getShopifyShops()
                        .then((response) => {
                          if (response?.data?.table_data) {
                            setShopifySites(response.data.table_data);
                          }
                        })
                        .catch((error) => {
                          console.error("Error fetching Shopify sites:", error);
                        });
                    } else {
                      setIntegrationError(response.message);
                    }
                  },
                  onError: () => {
                    setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                  }
                });
              }
            }} />
        </div>
        <p className={"has-text-danger mt-4"}>{integrationError}</p>
      </>
    )
  }

  function googleIntegration(integrationType: "google-search-console" | "google-analytics" | "google-drive") {
    errorAlertRef.current?.close();
    setGSCIntegrationProcessing(true);
    googleIntegrationMut.mutate(integrationType, {
      onSuccess: (data) => {
        const response: GoogleIntegrationResponse = data['data'];
        if (response.success) {
          successAlertRef.current?.show("New GSC Account Integrated successfully.");
          localStorage.setItem('integration-type', integrationType);
          window.location.href = response.authorization_endpoint;
        } else {
          setGSCIntegrationProcessing(false);
          errorAlertRef.current?.show(
            "Oops! Something went wrong :( Please try " +
            "again later or contact us for further support."
          );
        }
      },
      onError: () => {
        setGSCIntegrationProcessing(false);
        errorAlertRef.current?.show(
          "Oops! Something went wrong :( Please try " +
          "again later or contact us for further support."
        );
      }
    })
  }

  function ghostModalContent() {
    return (
      <>
        <div className={"integration-modal-logo"}>
          <img src={abunLogo} alt="abun_logo" width={"auto"} height={35} className={"abun-modal-logo"} />
          <img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
          <img src={ghostLogo} alt="wp_logo" width={"auto"} height={64} />
        </div>
        <div className={"block integration-modal-content"}>
          <h1 className="title is-4 mb-3">Integrating Abun with Your Ghost Site</h1>
          <p>To get started, follow these steps: </p>
          <ol className="ml-5 mt-2 integration-steps">
            <ol className="ml-5 mt-2 integration-steps">
              <li>Log in to your Ghost Admin dashboard.</li>
              <li>Go to "Settings" by clicking the ⚙️ icon.</li>
              <li>Navigate to "Integrations".</li>
              <li>Click "Add Custom Integration" and enter a name "Abun Integrations".</li>
              <li>Copy the generated Admin API Key.</li>
              <li>Copy your Ghost URL (e.g., https://yoursite.ghost.io).</li>
            </ol>
          </ol>
        </div>
        <div className={"mt-5"}>
          <label className={"label"}>
            Ghost Site URL: (e.g., https://yoursite.ghost.io or https://yoursite.com).
            <Input value={ghostSiteURL}
              type={"text"}
              placeholder={"ex. https://***.ghost.io"}
              className={"integration-input"}
              onChange={(val) => {
                setGhostSiteURL(val);
              }} />
          </label>
          <label className={"label mt-5"}>
            Ghost Admin API key:
            <Input value={ghostAPIKey}
              type={"text"}
              placeholder={"ex. 67af*******:e***628**"}
              className={"integration-input"}
              onChange={(val) => {
                setGhostAPIKey(val);
              }} />
          </label>
          <GenericButton text={ghostIntegrationMut.isLoading ? "Connecting..." : "Connect"}
            type={"success"}
            disable={ghostIntegrationMut.isLoading}
            additionalClassList={["mt-6", "mb-2", "pl-6", "pr-6"]}
            clickHandler={() => {
              setIntegrationError("");

              if (!ghostAPIKey) {
                setIntegrationError("Please provide Ghost Admin API key")
              } else if (!ghostSiteURL) {
                setIntegrationError("Please provide Ghost Site URL")
              } else {
                const formattedURL = ghostSiteURL.startsWith("https://")
                  ? ghostSiteURL.trim()
                  : `https://${ghostSiteURL.trim()}`;
                ghostIntegrationMut.mutate({ ghost_admin_api_key: ghostAPIKey.trim(), ghost_api_url: formattedURL }, {
                  onSuccess: (data) => {
                    let response: ghostIntegrationResponse = data['data'];
                    if (response.success) {
                      successAlertRef.current?.show("New Ghost Account Integrated successfully.");
                      setIsModalGhostOpen(false);

                      // Fetch updated Ghost sites
                      getGhostSites()
                        .then((response) => {
                          if (response?.data?.table_data) {
                            setGhostSites(response.data.table_data);
                          }
                        })
                        .catch((error) => {
                          console.error("Error fetching Ghost sites:", error);
                        });

                    } else {
                      setIntegrationError(response.message);
                    }
                  },
                  onError: (error) => {
                    if (error.message) {
                      return setIntegrationError(error.message);
                    }
                    setIntegrationError("Oops! Something went wrong. Please try again in some time.");
                  }
                });
              }
            }} />
        </div>
        <p className={"has-text-danger mt-4"}>{integrationError}</p>
      </>
    )
  }

  function featureRequestModalContent() {
    return (
      <>
        <div className={"integration-modal-logo"}>
          <img src={abunLogo} alt="abun_logo" width={"auto"} height={64} />
        </div>
        <div className={"block"}>
          <p>
            We're always cooking up new features, and your input is the secret sauce! 🔥 <br />
            Tell us which CMS you want to connect and drop your wildest integration ideas below.
          </p>
        </div>
        <div className={"block"}>
          <p className={"font-secondary"}>
            We read everything (seriously). Hit submit and let's make it happen! 🎯🚀
          </p>
        </div>
        <div className={"mt-5"}>
          <label className={"label"}>
            Preferred CMS Integration?
            <TextArea value={featureRequestMessage}
              placeholder={"💡 Your Genius Idea"}
              onChange={(val) => {
                setFeatureRequestMessage(val);
              }} />
          </label>
        </div>
        <GenericButton text={featureRequestMut.isLoading ? "Sending..." : "Submit"}
          type={"success"}
          disable={featureRequestMut.isLoading}
          additionalClassList={["mt-6", "pl-6", "pr-6"]}
          clickHandler={() => {
            setFeatureRequestErrorMessage("");
            setFeatureRequestSuccessMessage("");

            const featureRequestMessageLength = featureRequestMessage.length;
            if (featureRequestMessageLength < 10 || featureRequestMessageLength > 500) {
              setFeatureRequestErrorMessage("Please keep it between 10-500 characters.");
            } else {
              featureRequestMut.mutate(featureRequestMessage, {
                onSuccess: (data) => {
                  let response: featureRequestResponse = data['data'];
                  if (response.success) {
                    setFeatureRequestSuccessMessage(response.message);
                  } else {
                    setFeatureRequestErrorMessage(response.message);
                  }
                },
                onError: () => {
                  setFeatureRequestErrorMessage("Oops! Something went wrong. Please try again in some time.");
                }
              });
            }
          }} />
        <div className="mt-4">
          <p className={"has-text-danger"}>{featureRequestErrorMessage}</p>
          <p className={"has-text-success"}>{featureRequestSuccessMessage}</p>
        </div>
      </>
    )
  }


  return (
    <div className="integration-card">
      <div className={"is-flex is-justify-content-center is-align-items-center is-flex-direction-column"}>
          <h1 style={{ fontWeight: "800", fontSize: "2rem", fontFamily: "Bricolage Grotesque"}}>Manage Integrations</h1>
          <p className={"is-size-6 mt-2 mb-4"}>A brief explanation like "Connect and manage your website integrations
          with ease."</p>
      </div>

      <div className="tabs">
        {/* Updated to dynamically include logos */}
        {Object.keys(tabLogos).map((tab) => (
          <button
            key={tab}
            className={`tab ${activeTab === tab ? "active" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            {/* Add logo to the left of tab name */}
            <img
              src={tabLogos[tab]}
              className="integration-item-logo"
              alt={`${tab} logo`}
            />
            {tab}
          </button>
        ))}
      </div>
      <div className="site-list">
        <button className="connect-btn-integration" onClick={() => setFeatureRequestModalActive(true)}>
          <span>Request Integration</span>
        </button>
        {loading ? (
              <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
                    <AbunLoader show={loading} height="20vh" />
              </div>
        ) : (
          <>
            {activeTab === "Wordpress" && (
              <>
                {/* <button className="connect-btn" onClick={() => setIsModalOpen(true)}>Connect More Sites <span 
                style={{fontSize: '24px', marginLeft: '5px',marginTop:'-4px'}}>+</span></button> */}
                <button className="connect-btn" onClick={() => setIsModalOpen(true)}>
                  <span>Connect More Sites</span>
                  <span >
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.75 9.5C15.75 9.64918 15.6907 9.79226 15.5852 9.89775C15.4798 10.0032 15.3367 10.0625 15.1875 10.0625H9.5625V15.6875C9.5625 15.8367 9.50324 15.9798 9.39775 16.0852C9.29226 16.1907 9.14918 16.25 9 16.25C8.85082 16.25 8.70774 16.1907 8.60225 16.0852C8.49676 15.9798 8.4375 15.8367 8.4375 15.6875V10.0625H2.8125C2.66332 10.0625 2.52024 10.0032 2.41475 9.89775C2.30926 9.79226 2.25 9.64918 2.25 9.5C2.25 9.35082 2.30926 9.20774 2.41475 9.10225C2.52024 8.99676 2.66332 8.9375 2.8125 8.9375H8.4375V3.3125C8.4375 3.16332 8.49676 3.02024 8.60225 2.91475C8.70774 2.80926 8.85082 2.75 9 2.75C9.14918 2.75 9.29226 2.80926 9.39775 2.91475C9.50324 3.02024 9.5625 3.16332 9.5625 3.3125V8.9375H15.1875C15.3367 8.9375 15.4798 8.99676 15.5852 9.10225C15.6907 9.20774 15.75 9.35082 15.75 9.5Z" fill="white" />
                    </svg>
                  </span>
                </button>

                <div className="header-row">
                  <span className="header">Site URL</span>
                  <span className="header-action-wordpress">Actions</span>
                </div>
                {wordpressSites.length > 0 ? (
                  wordpressSites.map((site, index) => (
                    <div key={index} className="site-row">
                      <span className="site-url" style={{ color: '#485FC7', cursor: 'pointer' }}
                        onClick={() => {
                          const url = site.site_url ? site.site_url : '--';

                          if (url === '--') {
                            return;
                          }
                          window.open(url, '_blank', 'noopener,noreferrer');
                        }}
                      >{site.site_url
                        ? site.site_url.length > 41
                          ? `${site.site_url.slice(0, 41)}...` : site.site_url
                        : '--'}</span>
                      <button className="delete-btn" onClick={() => handleDeleteClick("wordpress", site.site_url)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_48_5565)">
                            <g clip-path="url(#clip1_48_5565)">
                              <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                            <clipPath id="clip1_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="no-sites-connected">No WordPress Sites Connected</span>
                )}
                <div className="footer">
                  <span className="total">Total {wordpressSites.length} connected {activeTab} sites
                    <select className="per-page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span> Per Page</span>
                  </span>
                  <span className="pagination">
                    <span className="page-numbers">1 / 1</span>
                  </span>
                </div>

                {isModalOpen && (
                  <AbunModal
                    active={isModalOpen}
                    headerText={"Abun Integrations"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => {
                      setIsModalOpen(false);
                      setFailedToFetchWPRestRoutes(false);
                    }}
                  >
                    {wordpressModalContent()}
                  </AbunModal>
                )}

              </>
            )}
            {activeTab === "Webflow" && (
              <>
                <button className="connect-btn" onClick={() => setIsModalWebflowOpen(true)}>
                  <span>Connect More Sites</span>
                  <span >
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.75 9.5C15.75 9.64918 15.6907 9.79226 15.5852 9.89775C15.4798 10.0032 15.3367 10.0625 15.1875 10.0625H9.5625V15.6875C9.5625 15.8367 9.50324 15.9798 9.39775 16.0852C9.29226 16.1907 9.14918 16.25 9 16.25C8.85082 16.25 8.70774 16.1907 8.60225 16.0852C8.49676 15.9798 8.4375 15.8367 8.4375 15.6875V10.0625H2.8125C2.66332 10.0625 2.52024 10.0032 2.41475 9.89775C2.30926 9.79226 2.25 9.64918 2.25 9.5C2.25 9.35082 2.30926 9.20774 2.41475 9.10225C2.52024 8.99676 2.66332 8.9375 2.8125 8.9375H8.4375V3.3125C8.4375 3.16332 8.49676 3.02024 8.60225 2.91475C8.70774 2.80926 8.85082 2.75 9 2.75C9.14918 2.75 9.29226 2.80926 9.39775 2.91475C9.50324 3.02024 9.5625 3.16332 9.5625 3.3125V8.9375H15.1875C15.3367 8.9375 15.4798 8.99676 15.5852 9.10225C15.6907 9.20774 15.75 9.35082 15.75 9.5Z" fill="white" />
                    </svg>
                  </span>
                </button>
                <div className="header-row-webflow">
                  <span className="header">Site URL</span>
                  <span className="header">Site ID</span>
                  <span className="header">Collection ID</span>
                  <span className="header-action-webflow" >Actions</span>
                </div>
                {/* Dynamically render Webflow sites */}
                {webflowSites.length > 0 ? (
                  webflowSites.map((site, index) => (
                    <div key={index} className="site-row-webflow">
                      <span className="site-url" style={{ color: '#485FC7', cursor: 'pointer' }}
                        onClick={() => {
                          const url = site.site_url ? site.site_url : '--';

                          if (url === '--') {
                            return;
                          }
                          window.open(url, '_blank', 'noopener,noreferrer');
                        }}
                      >{site.site_url
                        ? site.site_url.length > 41
                          ? `${site.site_url.slice(0, 41)}...` : site.site_url
                        : '--'}</span>
                      <span className="site-url">{site.site_id}</span>
                      <span className="site-url">{site.collection_id}</span>
                      <button className="delete-btn" onClick={() => handleDeleteClick("webflow", site.collection_id)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_48_5565)">
                            <g clip-path="url(#clip1_48_5565)">
                              <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                            <clipPath id="clip1_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="no-sites-connected" >No Webflow Sites Connected</span>
                )}
                <div className="footer">
                  <span className="total">Total {webflowSites.length} connected {activeTab} sites
                    <select className="per-page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span> Per Page</span>
                  </span>
                  <span className="pagination">
                    <span className="page-numbers">1 / 1</span>
                  </span>
                </div>

                {isModalWebflowOpen && (
                  <AbunModal
                    active={isModalWebflowOpen}
                    headerText={"Abun Integrations"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => setIsModalWebflowOpen(false)}
                  >
                    {webflowModalContent()}
                  </AbunModal>
                )}
              </>
            )}
            {activeTab === "WIX" && (
              <>
                <button className="connect-btn" onClick={() => setIsModalWIXOpen(true)}>
                  <span>Connect More Sites</span>
                  <span >
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.75 9.5C15.75 9.64918 15.6907 9.79226 15.5852 9.89775C15.4798 10.0032 15.3367 10.0625 15.1875 10.0625H9.5625V15.6875C9.5625 15.8367 9.50324 15.9798 9.39775 16.0852C9.29226 16.1907 9.14918 16.25 9 16.25C8.85082 16.25 8.70774 16.1907 8.60225 16.0852C8.49676 15.9798 8.4375 15.8367 8.4375 15.6875V10.0625H2.8125C2.66332 10.0625 2.52024 10.0032 2.41475 9.89775C2.30926 9.79226 2.25 9.64918 2.25 9.5C2.25 9.35082 2.30926 9.20774 2.41475 9.10225C2.52024 8.99676 2.66332 8.9375 2.8125 8.9375H8.4375V3.3125C8.4375 3.16332 8.49676 3.02024 8.60225 2.91475C8.70774 2.80926 8.85082 2.75 9 2.75C9.14918 2.75 9.29226 2.80926 9.39775 2.91475C9.50324 3.02024 9.5625 3.16332 9.5625 3.3125V8.9375H15.1875C15.3367 8.9375 15.4798 8.99676 15.5852 9.10225C15.6907 9.20774 15.75 9.35082 15.75 9.5Z" fill="white" />
                    </svg>
                  </span>
                </button>
                <div className="header-row-wix">
                  <span className="header">Site URL</span>
                  <span className="header">Site ID</span>
                  <span className="header-action-wix">Actions</span>
                </div>
                {/* Dynamically render WIX sites */}
                {WIXSites.length > 0 ? (
                  WIXSites.map((site, index) => (
                    <div key={index} className="site-row-wix">
                      <span className="site-url" style={{ color: '#485FC7', cursor: 'pointer' }}
                        onClick={() => {
                          const url = site.site_url ? site.site_url : '--';

                          if (url === '--') {
                            return;
                          }
                          window.open(url, '_blank', 'noopener,noreferrer');
                        }}
                      >{site.site_url
                        ? site.site_url.length > 41
                          ? `${site.site_url.slice(0, 41)}...` : site.site_url
                        : '--'}
                      </span>
                      <span className="site-url">{site.site_id}</span>
                      <button className="delete-btn-wix" onClick={() => handleDeleteClick("wix", site.site_id)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_48_5565)">
                            <g clip-path="url(#clip1_48_5565)">
                              <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                            <clipPath id="clip1_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="no-sites-connected">No Wix Sites Connected</span>
                )}
                <div className="footer">
                  <span className="total">Total {WIXSites.length} connected {activeTab} sites
                    <select className="per-page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span> Per Page</span>
                  </span>
                  <span className="pagination">
                    <span className="page-numbers">1 / 1</span>
                  </span>
                </div>

                {isModalWIXOpen && (
                  <AbunModal
                    active={isModalWIXOpen}
                    headerText={"Abun Integrations"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => setIsModalWIXOpen(false)}
                  >
                    {wixModalContent()}
                  </AbunModal>
                )}
              </>
            )}
            {activeTab === "Shopify" && (
              <>
                <button className="connect-btn" onClick={() => setIsModalShopifyOpen(true)}>
                  <span>Connect More Sites</span>
                  <span >
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.75 9.5C15.75 9.64918 15.6907 9.79226 15.5852 9.89775C15.4798 10.0032 15.3367 10.0625 15.1875 10.0625H9.5625V15.6875C9.5625 15.8367 9.50324 15.9798 9.39775 16.0852C9.29226 16.1907 9.14918 16.25 9 16.25C8.85082 16.25 8.70774 16.1907 8.60225 16.0852C8.49676 15.9798 8.4375 15.8367 8.4375 15.6875V10.0625H2.8125C2.66332 10.0625 2.52024 10.0032 2.41475 9.89775C2.30926 9.79226 2.25 9.64918 2.25 9.5C2.25 9.35082 2.30926 9.20774 2.41475 9.10225C2.52024 8.99676 2.66332 8.9375 2.8125 8.9375H8.4375V3.3125C8.4375 3.16332 8.49676 3.02024 8.60225 2.91475C8.70774 2.80926 8.85082 2.75 9 2.75C9.14918 2.75 9.29226 2.80926 9.39775 2.91475C9.50324 3.02024 9.5625 3.16332 9.5625 3.3125V8.9375H15.1875C15.3367 8.9375 15.4798 8.99676 15.5852 9.10225C15.6907 9.20774 15.75 9.35082 15.75 9.5Z" fill="white" />
                    </svg>
                  </span>
                </button>
                <div className="header-row">
                  <span className="header">Shop URL</span>
                  <span className="header-action-wordpress">Actions</span>
                </div>
                {/* Dynamically render Shopify sites */}
                {ShopifySites.length > 0 ? (
                  ShopifySites.map((site, index) => (
                    <div key={index} className="site-row">
                      <span className="site-url" style={{ color: '#485FC7', cursor: 'pointer' }}
                        onClick={() => {
                          const url = site.shop_url ? site.shop_url : '--';

                          if (url === '--') {
                            return;
                          }
                          window.open(`https://${url}`, '_blank', 'noopener,noreferrer');
                        }}
                      >{site.shop_url ? site.shop_url : "--"}</span>
                      <button className="delete-btn" onClick={() => handleDeleteClick("shopify", site.shop_url)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_48_5565)">
                            <g clip-path="url(#clip1_48_5565)">
                              <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                            <clipPath id="clip1_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="no-sites-connected">No Shopify Sites Connected</span>
                )}
                <div className="footer">
                  <span className="total">Total {ShopifySites.length} connected {activeTab} sites
                    <select className="per-page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span> Per Page</span>
                  </span>
                  <span className="pagination">
                    <span className="page-numbers">1 / 1</span>
                  </span>
                </div>

                {isModalShopifyOpen && (
                  <AbunModal
                    active={isModalShopifyOpen}
                    headerText={"Abun Integrations"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => setIsModalShopifyOpen(false)}
                  >
                    {shopifyModalContent()}
                  </AbunModal>
                )}
              </>
            )}
            {activeTab === "GSC" && (
              <>
                {!GSCSites || GSCSites.length === 0 ? (
                  <AbunButton className="connect-btn" type="primary" disabled={GSCIntegrationProcessing}
                    clickHandler={() => googleIntegration("google-search-console")}>
                    <span>{GSCIntegrationProcessing ? "Connecting..." : "Connect GSC"}</span>
                  </AbunButton>
                ) : null}
                <div className="header-row">
                  <span className="header">Integration Type</span>
                  <span className="header-action-wordpress">Actions</span>
                </div>
                {GSCSites ? ( // Render only if GSC sites are available
                  <div className="site-row">
                    <span className="site-url">{GSCSites}</span>
                    <button className="delete-btn" onClick={() => handleDeleteClick("google-search-console", "")}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_48_5565)">
                          <g clip-path="url(#clip1_48_5565)">
                            <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_48_5565">
                            <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                          </clipPath>
                          <clipPath id="clip1_48_5565">
                            <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                ) : (
                  <span className="no-sites-connected">No GSC Sites Connected</span>
                )}
                <div className="footer">
                  <span className="total">Total 1 connected {activeTab} sites
                    <select className="per-page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span> Per Page</span>
                  </span>
                  <span className="pagination">
                    <span className="page-numbers">1 / 1</span>
                  </span>
                </div>
              </>
            )}
            {activeTab === "Ghost" && (
              <>
                <button className="connect-btn" onClick={() => setIsModalGhostOpen(true)}>
                  <span>Connect More Sites</span>
                  <span >
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.75 9.5C15.75 9.64918 15.6907 9.79226 15.5852 9.89775C15.4798 10.0032 15.3367 10.0625 15.1875 10.0625H9.5625V15.6875C9.5625 15.8367 9.50324 15.9798 9.39775 16.0852C9.29226 16.1907 9.14918 16.25 9 16.25C8.85082 16.25 8.70774 16.1907 8.60225 16.0852C8.49676 15.9798 8.4375 15.8367 8.4375 15.6875V10.0625H2.8125C2.66332 10.0625 2.52024 10.0032 2.41475 9.89775C2.30926 9.79226 2.25 9.64918 2.25 9.5C2.25 9.35082 2.30926 9.20774 2.41475 9.10225C2.52024 8.99676 2.66332 8.9375 2.8125 8.9375H8.4375V3.3125C8.4375 3.16332 8.49676 3.02024 8.60225 2.91475C8.70774 2.80926 8.85082 2.75 9 2.75C9.14918 2.75 9.29226 2.80926 9.39775 2.91475C9.50324 3.02024 9.5625 3.16332 9.5625 3.3125V8.9375H15.1875C15.3367 8.9375 15.4798 8.99676 15.5852 9.10225C15.6907 9.20774 15.75 9.35082 15.75 9.5Z" fill="white" />
                    </svg>
                  </span>
                </button>

                <div className="header-row">
                  <span className="header">Site URL</span>
                  <span className="header-action-wordpress">Actions</span>
                </div>
                {GhostSites.length > 0 ? (
                  GhostSites.map((site, index) => (
                    <div key={index} className="site-row">
                      <span className="site-url" style={{ color: '#485FC7', cursor: 'pointer' }}
                        onClick={() => {
                          const url = site.site_url ? site.site_url : '--';

                          if (url === '--') {
                            return;
                          }
                          window.open(url, '_blank', 'noopener,noreferrer');
                        }}
                      >{site.site_url
                        ? site.site_url.length > 41
                          ? `${site.site_url.slice(0, 41)}...` : site.site_url
                        : '--'}</span>
                      <button className="delete-btn" onClick={() => handleDeleteClick("ghost", site.site_url)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_48_5565)">
                            <g clip-path="url(#clip1_48_5565)">
                              <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                            <clipPath id="clip1_48_5565">
                              <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="no-sites-connected">No Ghost Sites Connected</span>
                )}
                <div className="footer">
                  <span className="total">Total {GhostSites.length} connected {activeTab} sites
                    <select className="per-page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span> Per Page</span>
                  </span>
                  <span className="pagination">
                    <span className="page-numbers">1 / 1</span>
                  </span>
                </div>

                {isModalGhostOpen && (
                  <AbunModal
                    active={isModalGhostOpen}
                    headerText={"Abun Integrations"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => setIsModalGhostOpen(false)}
                  >
                    {ghostModalContent()}
                  </AbunModal>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* ==================================================================================================== */}
      {/* ------------------------------------- Integration Delete Alert ------------------------------------- */}
      {/* ==================================================================================================== */}

      <AbunModal
        active={integrationDeleteModal}
        headerText={"Abun Integrations"}
        closeable={false}
        hideModal={() => setIntegrationDeleteModal(false)}
      >
        <div>
          <h4 className={"is-size-4 has-text-centered"}>Confirm Deleting Integration?</h4>
          <p className={"has-text-centered mt-4"}>
            This will immediately remove the integration from your Abun account and stop your Content Automation & Schedule Article feature.
            Any pending Content Automation & Scheduled Article will not be published.
          </p>
          <div className={"mt-6 has-text-centered is-flex is-justify-content-center is-align-items-center"}>
            <AbunButton
              type={"danger"}
              disabled={removeIntegrationMut.isLoading}
              clickHandler={() => {
                removeIntegration(currentIntegration.type, currentIntegration.uniqueID);
                setIntegrationDeleteModal(false); // Close the modal after deletion
              }}
            >
              Yes, Delete
            </AbunButton>
            <AbunButton
              type={"primary"}
              className={"ml-4"}
              clickHandler={() => setIntegrationDeleteModal(false)} // Close the modal if canceled
            >
              Cancel
            </AbunButton>
          </div>
        </div>
      </AbunModal>


      {/* ==================================================================================================== */}
      {/* ------------------------------------- Integration Request Modal ------------------------------------- */}
      {/* ==================================================================================================== */}
      <AbunModal active={featureRequestModalActive}
        headerText={"🚀 Got an Integration Idea? Let's Hear It!"}
        closeable={true}
        closeableKey={true}
        hideModal={() => {
          setFeatureRequestModalActive(false);
          setFeatureRequestErrorMessage("");
          setFeatureRequestSuccessMessage("");
        }}>
        {featureRequestModalContent()}
      </AbunModal>

      <SuccessAlert ref={successAlertRef} />
      <ErrorAlert ref={errorAlertRef} />


    </div>
  );
};

export default NewIntegration;
