import React, { useState, useRef } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import './Glossary.scss';
import GenericButton from '../../components/GenericButton/GenericButton';
import { useMutation } from '@tanstack/react-query';
import { glossaryMutation } from '../../utils/api';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import AbunModal from '../../components/AbunModal/AbunModal';
import AbunLoader from "../../components/AbunLoader/AbunLoader";

interface UserData {
    verified: boolean
    username: string
    website: string
    email: string
    tz: string
    send_notif_email: boolean
}

const Glossary: React.FC = () => {
    // ----------------------------- LOADER -----------------------------
    const pageData: UserData = useLoaderData() as UserData;

    const [word, setWord] = useState('');
    const [loadingWord, setLoadingWord] = useState(false);
    const [proceedEnabled, setProceedEnabled] = useState(false);
    const [requestModalActive, setRequestModalActive] = useState(false);
    const navigate = useNavigate();

    const glossaryMut = useMutation(glossaryMutation);

    // --------------------------- Refs ---------------------------
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);

    const handleGenerateGlossary = () => {
        if (!word.trim()) return;
        setRequestModalActive(true);
        setLoadingWord(true);
        // Trigger the mutation
        glossaryMut.mutate(
            { word },
            {
                onSuccess: (response) => {
                    setRequestModalActive(false);
                    const { project_name, count, project_id } = response.data;
                    successAlertRef.current?.show('Glossary Words generated successfully!');
                    navigate(`/glossary-generator/${project_id}`);
                },
                onError: () => {
                    setRequestModalActive(false);
                    errorAlertRef.current?.show(
                        'Oops! Something went wrong. Please try again or contact support if the issue persists.'
                    );
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 5000);
                },
                onSettled: () => {
                    setLoadingWord(false);
                },
            }
        );
    };


    function goBack() {
        navigate(-1);
    }

    // Track input changes and update word state
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newWord = e.target.value;
        setWord(newWord);

        // Enable the Proceed button when there is input
        setProceedEnabled(newWord.trim().length > 0 && pageData.verified);
    };

    return (
        <div className="glossary-card">
            <span className={"back-btn"} onClick={goBack}>
                <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                </svg>
            </span>
            <h1 className={"is-size-4"} >Glossary Article Creator</h1>
            <div className="glossary-content">
                <h1 className="glossary-subtitle">Enter a Topic for Create a Glossary Terms</h1>
                <p className="glossary-description">
                    Example, "Graphic Design" generates a list of niche-relevant terms.<br />
                    Approve the terms, then create & post content.
                </p>
                <div className="glossary-input-container">
                    <input
                        type="text"
                        placeholder="Topic"
                        className="glossary-input"
                        value={word}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && proceedEnabled) {
                                handleGenerateGlossary();
                            }
                        }}
                    />

                </div>
                <GenericButton
                    text={"PROCEED"}
                    type="success"
                    additionalClassList={["mt-5"]}
                    clickHandler={handleGenerateGlossary}
                    disable={!proceedEnabled}
                />
            </div>

            {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
            <AbunModal
                active={requestModalActive}
                headerText={""}
                closeable={false}
                hideModal={() => setRequestModalActive(false)}
            >
                <div className="loadingData w-100 is-flex is-justify-content-center is-align-items-center">
                    <AbunLoader show={requestModalActive} height="20vh" />
                </div>
                <p className="is-size-4 has-text-centered mb-4">Generating Glossary Terms</p>
            </AbunModal>
  

            <SuccessAlert ref={successAlertRef} />
            <ErrorAlert ref={errorAlertRef} />

        </div>
    );
};

export default Glossary;
