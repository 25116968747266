import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import "./ChangeLog.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getChangeLogs,
    retryFn
} from "../../utils/api";

interface ChangeLog {
    id: string
    title: string,
    description: string,
    created_at: string,
}

const Changelog = () => {

    // -------------------------- STATES --------------------------
    const [changeLogs, setChangeLogs] = useState<Array<ChangeLog>>([]);
    const [expandedIndex, setExpandedIndex] = useState(null);

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch,
    } = useQuery({
        queryKey: ['getChangeLogs'],
        queryFn: getChangeLogs,
        refetchOnWindowFocus: false,
        retry: retryFn
    });

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setChangeLogs(data['data']);
        }
    }, [data]);

    useEffect(() => {
		document.title = "Changelog & Updates | Abun"
	}, []);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        }).replace(",", ""); // Removes the comma after the date
    };

    const formatDescription = (description) => {
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Regex to detect URLs with http, https, or www
        
        const withLinks = description.split('\n').map((line, index) => {
          // Split line into parts: before and after the URL
          const parts = line.split(urlRegex).map((part, i) => {
            if (urlRegex.test(part)) {
              // If part is a URL, wrap it in an <a> tag
              let url = part;
              // If the link starts with "www.", prepend "http://" to make it work properly
              if (url.startsWith('www.')) {
                url = `http://${url}`;
              }
              return (
                <a key={i} href={url} target="_blank" rel="noopener noreferrer">
                  {part}
                </a>
              );
            }
            return part;
          });
    
          return (
            <React.Fragment key={index}>
              {parts}
              {index < description.split('\n').length - 1 && <br />}
            </React.Fragment>
          );
        });
    
        return withLinks;
    };
    

    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...<FontAwesomeIcon icon={"spinner"} className={"ml-5"} />
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">ChangeLogs</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <div className="changelog-container">
                <Helmet>
                    <meta name="description" content="Stay in the loop with the latest features, fixes, and improvements on Abun. Check out our changelog for all the new enhancements designed to make your experience even better!" />
                </Helmet>
                <h2 className="changelog-title">Updates</h2>

                {changeLogs.length > 0 ? (
                    <div className="space-y-4">
                        {changeLogs.map((item, index) => {
                            return (
                                <div key={index} className="changelog-card">
                                    <div className="changelog-header">
                                        <h3 className="changelog-title-text">{item.title}</h3>
                                    </div>
                                    <p className="changelog-description">
                                       {formatDescription(item.description)}
                                    </p>
                                </div>
                            )
                        }
                        )}
                    </div>
                ) : (
                    <p className="no-updates">No updates available.</p>
                )}
            </div>
        );
    }
};

export default Changelog;
