import { useMutation } from "@tanstack/react-query";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState, useRef } from "react";
import Select, { components } from 'react-select';
import { saveSettingsMutation, saveContextMutation, getLatestContext } from "../../utils/api";
import allSupportedLanguagesOptions from "../../utils/constants/allSupportedLanguagesOptions";
import AbunButton from "../../components/AbunButton/AbunButton";
import plusIcon from "../../assets/images/icons/plus-icon.png";
import AbunModal from "../../components/AbunModal/AbunModal";
import { useQuery } from "@tanstack/react-query";
import uploadIcon from "../../assets/images/icons/cloud-upload.svg";
import AbunLoader from '../../components/AbunLoader/AbunLoader';

interface ArticleDetailsProps {
    errorAlertRef: MutableRefObject<any>
    successAlertRef: MutableRefObject<any>
    article_tone_of_voice: string
    languagePreference: string
    articleContext: string
    updatePageData: () => void
    setUnsavedChanges: Dispatch<SetStateAction<boolean>>
}

export type Language = {
    label: string;
    value: string;
}

export default function ArticleDetails(props: ArticleDetailsProps) {
    // --------------------- STATES ---------------------
    const [toneOfVoice, setToneOfVoice] = useState<string>(props.article_tone_of_voice);
    const [languagePreference, setLanguagePreference] = useState<string>(props.languagePreference);
    const [showAddContextModal, setShowAddContextModal] = useState(false);
    const [articleContext, setArticleContext] = useState(props.articleContext || "");
    const [saveArticleContext, setSaveArticleContext] = useState("")
    const [oldContext, setOldContext] = useState("")    
    const [modalActive, setModalActive] = useState(false);
    // ---------------------------- MUTATIONS ----------------------------
    const saveSettings = useMutation(saveSettingsMutation);
    const saveInstructionContext = useMutation(saveContextMutation)
    
    const { data, isFetching, error, refetch } = useQuery({
            queryKey: ['getLatestContext'],
            queryFn: () => getLatestContext(),
            cacheTime: 0,
            refetchOnWindowFocus: false,
        });
    
    // --------------------- FUNCTIONS ---------------------
    const options = data?.data?.contexts?.map((context) => ({
        label: context,
        value: context,
    })) || [];

    function saveContext(context: string, oldContext: string){        

        saveInstructionContext.mutate({context, oldContext: oldContext || "" },{
            onSuccess: () => {
                props.updatePageData();
                props.setUnsavedChanges(false);
                setSaveArticleContext("")    
                setShowAddContextModal(false)            
                props.successAlertRef.current.show("Context Saved!");
                refetch()
                setTimeout(() => {
                    try {
                        if (props.successAlertRef.current) {
                            props.successAlertRef.current.close();
                        }
                    } catch (e) { }
                }, 3000);
            },
            onError: () => {                
                props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
            }
        });
    }


    function handleLanguageChange(selectedOption: Language | null) {
        const newLanguage = selectedOption?.value || "";
        setLanguagePreference(newLanguage);
    
        // Trigger save settings immediately
        saveSettings.mutate({
            settingsToSave: [
                { settingName: 'article_language_preference', settingValue: newLanguage },
                { settingName: 'article_tone_of_voice', settingValue: toneOfVoice }
            ]
        }, {
            onSuccess: () => {
                props.updatePageData();
                props.setUnsavedChanges(false);
                props.successAlertRef.current.show("Changes Saved!");
                setTimeout(() => {
                    try {
                        if (props.successAlertRef.current) {
                            props.successAlertRef.current.close();
                        }
                    } catch (e) { }
                }, 3000);
            },
            onError: () => {
                props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
            }
        });
    }

    function handleContextChange(Context: Language) {
        const selectedContext = Context?.value || "";
        if (selectedContext == articleContext){
            return;
        }
        setArticleContext(selectedContext);
        // Trigger save settings immediately
        saveSettings.mutate({
            settingsToSave: [
                { settingName: 'article_context', settingValue: selectedContext },                
            ]
        }, {
            onSuccess: () => {
                props.updatePageData();
                props.setUnsavedChanges(false);
                props.successAlertRef.current.show("Changes Saved!");
                setTimeout(() => {
                    try {
                        if (props.successAlertRef.current) {
                            props.successAlertRef.current.close();
                        }
                    } catch (e) { }
                }, 3000);
            },
            onError: () => {
                props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
            }
        });
    }

    const CustomOption = (props) => {
        const { data, innerRef, innerProps } = props;
        const [isHovered, setIsHovered] = useState(false);
    
        return (
            <div
                ref={innerRef}
                {...innerProps}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                    cursor: "pointer",
                    background: isHovered ? "#f0f0f0" : "transparent",
                    position: "relative",
                }}
            >
                <span style={{ flex: 1 }}>
                    {data.label.length > 50 ? `${data.label.substring(0, 50)}...` : data.label}
                </span>
    
                {/* Show edit button only when hovered */}
                {isHovered && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent dropdown from closing
                            handleEditContext(data.value); // Call edit function
                        }}
                        className="internal-link-edit-button"
                        style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            fontSize: "14px",
                            marginLeft: "10px",
                        }}
                    >
                        ✏️
                    </button>
                )}
            </div>
        );
    };
    

        // Custom styles for truncation
        const customStyles = {
            control: (provided) => ({
                ...provided,
                width: '300px',
                minHeight: '40px',
            }),
            option: (provided) => ({
                ...provided,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                display: 'block',
            }),
            singleValue: (provided) => ({
                ...provided,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
            }),
        };
    
    function handleEditContext(context){
        setSaveArticleContext(context)
        setOldContext(context)
        setShowAddContextModal(true)
    }

                
	// on tab change, check if there are unsaved changes
    useEffect(() => {
        if (props.article_tone_of_voice !== toneOfVoice || props.languagePreference !== languagePreference) {
            props.setUnsavedChanges(true);
        } else {
            props.setUnsavedChanges(false);
        }
    } , [toneOfVoice, languagePreference, props]);
    // =====================================================
    // --------------------- MAIN CODE ---------------------
    // =====================================================
    return (
        <>
            {/* -=-=-=-=-=-=-=-=-=-=-= Article Tone of Voice Section -=-=-=-=-=-=-=-=-=-=-= */}
            <div className="card settings-section">
                <div className="card-content">
                    {/* Just removing the tone used in the article by commenting the code */}
                    {/* <h5 className="settings-section-title">What should be the tone used in the article?</h5>
                    <div className="control mt-5">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="off"
                                onChange={() => setToneOfVoice('off')}
                                checked={toneOfVoice === 'off'}
                            />
                            <span className="settings-radio-item-title">Off</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="exciting"
                                onChange={() => setToneOfVoice('exciting')}
                                checked={toneOfVoice === 'exciting'}
                            />
                            <span className="settings-radio-item-title">Exciting</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="humorous"
                                onChange={() => setToneOfVoice('humorous')}
                                checked={toneOfVoice === 'humorous'}
                            />
                            <span className="settings-radio-item-title">Humorous</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="funny"
                                onChange={() => setToneOfVoice('funny')}
                                checked={toneOfVoice === 'funny'}
                            />
                            <span className="settings-radio-item-title">Funny</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="formal"
                                onChange={() => setToneOfVoice('formal')}
                                checked={toneOfVoice === 'formal'}
                            />
                            <span className="settings-radio-item-title">Formal</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="serious"
                                onChange={() => setToneOfVoice('serious')}
                                checked={toneOfVoice === 'serious'}
                            />
                            <span className="settings-radio-item-title">Serious</span>
                        </label>
                    </div> */}
                    <div className="mt-5">
                        <h5 className="settings-section-title">What should be the language of the generated articles?</h5>
                        <p className="is-size-6 mt-3 mb-2 ml-2"><b>{allSupportedLanguagesOptions.find(option => option.value === props.languagePreference)?.label}</b> (Language of Articles)</p>
                        <Select
                            id="other-language-input"
                            options={allSupportedLanguagesOptions}
                            defaultValue={allSupportedLanguagesOptions.find(option => option.value === props.languagePreference)}
                            // onChange={(e) => setLanguagePreference(e?.value || '')}
                            onChange={handleLanguageChange}
                        />
                    </div>
                    <div className="mt-5">
                        <h5 className="settings-section-title">Instructions & Context</h5>
                        <div className="mt-2 is-flex is-justify is-flex-direction-row">
                        <Select
                        className="mr-2 select-context"
                        options={options}
                        value={options.find(option => option.value.trim() === articleContext?.trim()) || null}
                        styles={customStyles}
                        onChange={handleContextChange}
                        components={{ 
                            Option: CustomOption                         
                        }}
                        />
                        <AbunButton
                            type={"primary"}
                            clickHandler={() => {setShowAddContextModal(true)}}
                            disabled={false}>                            
                            <span> + Context & Instructions</span>
                        </AbunButton>
                        </div>
                        {/* ------------------------------ CREATE CONTEXT MODAL ------------------------------*/}
                        <AbunModal active={showAddContextModal}
                            headerText={"Save a context for article"}
                            closeable={true}
                            closeableKey={true}
                            hideModal={() => {
                                setShowAddContextModal(false)
                            }}>
                            <div className={"has-text-centered"}>
                                <textarea
                                    rows={10}
                                    className="ca-input"
                                    value={saveArticleContext}
                                    placeholder="Include MyCompany.com at the top of the list. Also talk more about how MyCompany.com helps with This, This & That"
                                    onChange={(e) => setSaveArticleContext(e.target.value)}
                                    style={{ textAlign: 'start' }}
                                    required
                                />
                                <AbunButton type={"success"}
                                    className={"mt-4"}
                                    disabled={saveArticleContext !== "" ? false :  true}
                                    clickHandler={() => {
                                        saveContext(saveArticleContext, oldContext)
                                    }}>
                                    Proceed
                                </AbunButton>
                            </div>
                        </AbunModal>                        
                        <AbunModal
                            active={modalActive}
                            headerText=""
                            closeable={false}
                            hideModal={() => setModalActive(false)}
                        >
                            <div className="loadingData w-100 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                                <AbunLoader show={modalActive} height="20vh" />
                                <div style={{ fontSize: '1.3em' }}>Processing request. Please wait...</div>
                            </div>
                        </AbunModal>
                    </div>                    
                </div>
            </div>

            {/* -=-=-=-=-=-=-=-=-=-=-= Save All Settings Button -=-=-=-=-=-=-=-=-=-=-= */}
            {/* <div className="save-changes-section mt-4">
                <button
                    className={`button is-primary ${saveSettings.isLoading ? 'is-loading' : ''}`}
                    onClick={SaveAllSettings}
                >
                    Save Changes
                </button>
            </div> */}
        </>
    );
}
